<template lang="pug">
.v-product.product(v-if="product" :class="[template ? 'product--' + template : '', {'product--status-hit' : product.status && product.status.type === 'hit', 'product--status-new' : product.status && product.status.type === 'new'}]" :style="{'backgroundImage': template !== 'search-small-item' && product.status && product.status.image ? `url(${isSm ? product.status.image.srcSm : product.status.image.src})` : ''}")
	template(v-if="template === 'search-small-item'")
		a.product__absolute-link(v-if="product.link" :href="product.link")
		.product__left(v-if="product.images && product.images.length > 0 && product.images[0].medium")
			img.product__left-img(:src="product.images[0].medium" alt="")
		.product__right
			.product__title(v-html='product.name || product.title')
			.product__subtitle(v-html='product.text')
			.product__breadcrumbs
				template(v-for="(breadcrumb, breadcrumIndex) in product.breadcrumbs" :key="breadcrumIndex")
					a.product__breadcrumb(:href="breadcrumb.link") {{breadcrumb.text}}
	template(v-else)
		.product__banner(v-if="product.imageSources")
			img.product__banner-img(:src="product.imageSources.medium_2x" alt="")
		a.product__link(v-if="product.link" :href="product.link") {{product.title}}
		.product__top
			button.sticker.product__location(v-if="product.location" type="button" @click="openModal(product.location.modal)") {{product.location.text}}
			.product__status-list(v-if="product.status")
				a.sticker.product__status(:href="product.status.link" v-if="product.status" :style="{backgroundImage: `url(${product.status.icon})`}") {{product.status.text}}
		.product__content
			.product__name(v-if="product.name") {{product.name}}
			.product__text.text-guide(v-if="product.text" v-html="product.text")
</template>

<script>

import mixinIsMob from '../components/mixins/v-mixin-is-mob.vue';
import controller from '../scripts/controller/controller.js';

export default {
	mixins: [
		mixinIsMob
	],

	props: {
		product: {
			type: Object,
			default: null
		},

		template: {
			type: String,
			default: 'grid'
		},

		quickView: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		openModal(modal) {
			if (modal) {
				controller.openModal({
					className: 'modal--xl',
					url: modal
				});
			}
		}
	}
};
</script>
