import {createStore} from 'vuex';
import basket from './modules/basket';
import favorite from './modules/favorite';
import user from './modules/user';
import location from './modules/location';
import breadcrumbs from './modules/breadcrumbs';

const store = createStore({
	modules: {
		basket,
		favorite,
		user,
		location,
		breadcrumbs
	}
});

export default store;
