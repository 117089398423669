export const detectIOS = () => {
	let iOS = parseInt(
		('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
		.replace('undefined', '3_2').replace('_', '.').replace('_', '')
	) || false;
	if (iOS)  {
		document.body.classList.add('ios');
		document.body.classList.add('ios-' + iOS);
	} else {
		document.body.classList.add('no-ios');
	}
};