<template lang="pug">
header.v-page-header.page-header.js-page-header-wrap
	.container
		.page-header__container-inner.container-inner
			.page-header__layout
				template(v-if="$slots['logo']")
					.page-header__cell.page-header__cell--logo
						slot(name="logo")
				template(v-if="$slots['switcher']")
					.page-header__cell.page-header__cell--switcher
						slot(name="switcher")
				template(v-if="$slots['nav']")
					.page-header__cell.page-header__cell--nav
						slot(name="nav")
				.page-header__cell.page-header__cell--menu
					button.page-header__menu-btn(type="button" @click="openMobileNav", aria-label="меню")
						v-svg-icon.page-header__menu-icon(icon="menu")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import controller from '../scripts/controller/controller.js';
import breakpoints from '../scripts/breakpoints.js';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			menu: [],
			isMobile: true
		};
	},

	mounted() {
		this.$nextTick(() => {
			this.initHeaderFix();
		});
	},

	methods: {
		initHeaderFix() {
			// для производительности используем чистый JavaScript
			let header = document.body.querySelector('.js-page-header-wrap');
			let passiveSupported = false;
			let lastScrollY = window.scrollY;
			let scrolled = false;
			let fixed = false;
			let stop = true;
			let recalcTimeout = null;

			// для производительности используем passive event listener
			try {
				window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
					get: function () {
						passiveSupported = true;
					}
				}));
			} catch (err) {
			}

			window.addEventListener('resize', handleResize, passiveSupported ? {passive: true} : false);
			handleResize();

			function handleResize() {
				if (window.innerWidth >= 1000) {
					window.addEventListener('scroll', handleScroll, passiveSupported ? {passive: true} : false);
					handleScroll();
				} else if (window.innerWidth <= 999) {
					window.removeEventListener('scroll', handleScroll);
					document.body.classList.remove('state--header-fixed');
					document.body.classList.remove('state--header-fixed-hidden');
					document.body.classList.remove('state--header-scrolled');
				}
			}

			function handleScroll() {
				if (!recalcTimeout) {
					recalcTimeout = setTimeout(recalc, 50);
				}
			}

			function recalc() {
				// если скроллим вверх и достигли верхнего края экрана, или скроллим вниз и не проскроллили до конца хедер
				if (window.scrollY === 0) {
					if (!stop) {
						document.body.classList.remove('state--header-fixed');
						document.body.classList.remove('state--header-fixed-hidden');
						stop = true;
						fixed = false;
					}
				} else {
					// если скроллим где-то среди страницы
					// скролл вверх - фиксируем
					if (!fixed && lastScrollY > window.scrollY && window.scrollY > 600) {
						//document.body.classList.remove('state--header-static');
						document.body.classList.add('state--header-fixed');
						document.body.classList.remove('state--header-fixed-hidden');
						stop = false;
						fixed = true;
					} else if (fixed && lastScrollY < window.scrollY && window.scrollY > (header.offsetHeight + 100)) {
						// скролл вниз - прячем (и если хедер проскроллили)
						document.body.classList.remove('state--header-fixed');
						document.body.classList.add('state--header-fixed-hidden');
						stop = false;
						fixed = false;
					}
				}
				if (!scrolled && window.scrollY > (header.offsetHeight + 100)) {
					document.body.classList.add('state--header-scrolled');
					scrolled = true;
				} else if (!fixed && scrolled && window.scrollY <= (header.offsetHeight + 100)) {
					document.body.classList.remove('state--header-scrolled');
					scrolled = false;
				}
				lastScrollY = window.scrollY;
				recalcTimeout = null;
			}
		},

		dataSet(data) {
			this.menu.push(data);
		},

		openMobileNav() {
			controller.openMobileNav();
		},

		handleResize() {
			this.isMobile = window.innerWidth < breakpoints['xs-max'];
		}
	}
};
</script>
