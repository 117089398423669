export default {
	namespaced: true,

	state: {
		bxajaxid: null,
		enabled: null,
		remote: null,
		searchRemote: null,
		APIPromise: null,
		coords: null,
		cityId: null,
		items: [],
		selected: false,
		APIKey: null, //ToDo тут нужно заполнить APIKey,
		loading: false
	},

	getters: {
		city(state) {
			return state.city;
		},

		coords(state) {
			return state.coords;
		},

		selected(state) {
			return state.selected;
		},

		searchRemote(state) {
			return state.searchRemote;
		},

		enabled(state) {
			return state.enabled;
		}
	},

	mutations: {
		set(state, data) {
			if (data) {
				state.bxajaxid = data.bxajaxid;
				state.enabled = data.enabled;
				state.remote = data.remote;
				state.searchRemote = data.searchRemote;
				state.coords = data.coords;
				state.cityId = data.cityId;
				state.items = data.items;
			}
		},

		setCoords(state, value) {
			state.coords = value;
		},

		setAPIPromise(state, value) {
			state.APIPromise = value;
		},

		setLoading(state, value) {
			state.loading = value;
		}
	},

	actions: {
		async loadAPI({state, commit}) {
			if (!state.APIPromise) {
				let promise = new Promise((resolve, reject) => {
					let script = document.createElement('script');
					script.setAttribute('src', 'https://api-maps.yandex.ru/2.1/?apikey='+state.APIKey+'&lang=ru_RU');
					script.setAttribute('async', '');
					script.onload = () => {
						// eslint-disable-next-line no-undef
						ymaps.ready(() => {
							resolve();
						});
					};
					script.onerror = () => {
						reject();
					};
					document.body.appendChild(script);
				});
				commit('setAPIPromise', promise);
			}
			return state.APIPromise;
		},

		async update({state, commit}, options) {
			commit('setLoading', true);
			const response = await fetch(state.remote, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					action: 'set',
					bxajaxid: state ? state.bxajaxid : null,
					cityId: options ? options.cityId : null,
					coords: options ? options.coords : null
				})
			});
			if (!response.ok) {
				console.warn('[vuex] location: update request failed', response);
			} else {
				if (response.data) {
					commit('set', response.data);
					if (window.location.hostname.indexOf('localhost') < 0) {
						commit('set', response.data);
						console.log('location updated, todo - reload page');
						//window.location.reload();
					}
				}
			}
			commit('setLoading', false);
		},

		async search({state, commit, dispatch}) {
			if (state.coords) {
				return;
			}
			await dispatch('loadAPI');
			// eslint-disable-next-line no-undef
			const loc = await ymaps.geolocation.get({
				provider: 'auto', // опция для определения положения по ip
				autoReverseGeocode: true // автоматическое геокодируем полученного результата
			});
			const coords = loc.geoObjects.get(0).geometry.getCoordinates();
			commit('setCoords', coords);
			await dispatch('update', {coords});
		}
	}
};