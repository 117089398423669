<template lang="pug">
form.v-big-form.big-form(v-if="form" @submit.prevent="submit")
	.big-form__container
		.big-form__wrap
			v-loader(:loading="loading")
			h1.big-form__title.h3(v-if="form.title") {{form.title}}
			.big-form__tabs.scrollbar-none
				slot
			.big-form__main
				.big-form__groups
					template(v-for="(group, groupIndex) in form.groups" :key="groupIndex")
						.big-form__group.flc(:class="{'big-form__group--no-title': !group.title}")
							h2.big-form__group-title.h4.flc(v-if="group.title") {{group.title}}
							.big-form__group-body.flc
								.big-form__fields
									template(v-for="(field, index) in form.fields" :key="index")
										template(v-if="field.groupId === group.id")
											.big-form__field-wrap
												.big-form__field-label(v-if="field.bigLabel" :class="{'big-form__field-label--required': field.required}")
													.big-form__field-label-text {{field.bigLabel}}
												v-field.big-form__field(:field="field"
														v-model="field.value"
														@fileinput="fileInput(field, $event)"
														@fileremove="fileRemove(field, $event)"
														@remove="valueRemove(field, $event)"
														@update:model-value="fieldChanged"
														:style="{'width': field.width}")
			.big-form__confirm-wrap(v-if="form.requireConfirm && form.requireConfirmText")
				v-field.field--confirm-checkbox(:field="computedConfirmField" :model-value="confirmValue" v-model="confirmed")
			.big-form__actions(v-if="form.buttons")
				button.btn.big-form__action(v-if="form.buttons.submitBtn" type="submit" :class="form.buttons.submitBtn.className" :disabled="!confirmed") {{form.buttons.submitBtn.text}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import controller from '../scripts/controller/controller.js';
import {nextTick} from "vue";

export default {
	mixins: [
		sourceMixin
	],

	props: {
		mode: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			form: null,
			localFormData: new FormData(),
			isSpoilerOpened: false,
			confirmValue: [],
			confirmed: false
		};
	},

	computed: {
		computedConfirmField() {
			return {
				type: 'check-list',
				value: ['1'],
				label: null,
				options: [
					{
						'value': '1',
						'text': this.form.requireConfirmText
					}
				]
			};
		}
	},

	methods: {
		scrollToFirstInvalidField() {
			const $invalidField = this.$el?.querySelector('.form-error');
			if ($invalidField) {
				window.scrollTo({
					top: $invalidField.offsetTop,
					behavior: "smooth"
				});
			}
		},

		sendTab(tab) {
			if (!tab.active) {
				this.dataLoad({
					url: this.form.remote,
					data: {
						"tab": tab.id
					},
					delay: true
				});
			}
		},

		proxyFieldName(name) {
			return `state[form][${name}]`;
		},

		dataSet(data) {
			if (data.success && data.closeModal) {
				controller.closeModal();
				return;
			}
			this.form = data;
			this.$nextTick(() => {
				this.scrollToFirstInvalidField();
			});
		},

		submit(options = {}) {
			let namesOfCurrentIteration = [];
			if (this.form && this.form.fields) {
				this.form.fields.forEach(field => {
					let key = this.proxyFieldName(field.name);
					if (field.multiple) {
						key = this.proxyFieldName(field.name.replace('[]', '')) + '[]';
					}
					if (!field.value) {
						field.value = '';
					}
					if (field.type === 'file') {
						// обработка файлов происходит в fileInput / fileRemove
						// здесь ничего делать не нужно
						/*
						 _.each(field.value, (fileListItem) => {
						 this.localFormData.append(field.name, fileListItem.file, fileListItem.name)
						 });
						 */
					} else {
						// для отправки файлов придется держать в памяти неизменный localFormData
						// перед отправкой нужно удалить старые значения из localFormData
						// т.к. localFormData.append добавляет новые значения к существующим
						// а localFormData.set не позволила бы работать с массивами типа NAME[]
						if (namesOfCurrentIteration.indexOf(key) < 0) {
							this.localFormData.delete(key);
							namesOfCurrentIteration.push(key);
						}
						if (Array.isArray(field.value)) {
							field.value.forEach((val, index) => {
								this.localFormData.append(`${key}[${index}]`, val);
							});
						} else {
							this.localFormData.append(key, field.value);
						}
					}
				});
			}
			this.localFormData.set('state[action]', options.action || 'submit');
			this.localFormData.set('bxajaxid', this.bxajaxid);
			this.dataLoad({
				url: this.form.remote,
				data: this.localFormData,
				delay: true
			});
		},

		fieldChanged(field) {
			if (field.autosubmit) {
				this.submit({action: 'autosubmit'});
			}
		},

		fileInput(field, e) {
			for (let i = 0; i < e.target.files.length; i++) {
				this.localFormData.append(this.proxyFieldName(field.name) + '[' + e.key + ']', e.target.files[i].path || e.target.files[i]);
			}
			if (field.autosubmit && !e.nosubmit) {
				this.submit({action: 'autosubmit'});
			}
		},

		fileRemove(field, e) {
			this.localFormData.delete(this.proxyFieldName(field.name) + '[' + e.key + ']');
			if (field.autosubmit && !e.nosubmit) {
				this.submit({action: 'autosubmit'});
			}
		},

		valueRemove(field, e) {
			// remove error of removed value
			if (field.errors && field.errors.length) {
				field.errors.splice(e, 1);
			}
		},

		closeModal() {
			controller.closeModal();
		}
	}
};

</script>
