<script>
import breakpoints from '../../scripts/breakpoints';
export default {
	data() {
		return {
			isMd: false,
			isSm: false,
			isXs: false
		};
	},

	created() {
		this.getIsMob(true);
		window.addEventListener('resize', this.getIsMob);
	},

	unmounted() {
		window.removeEventListener('resize', this.getIsMob);
	},

	methods: {
		checkBreakpoints() {
			this.isMd = window.innerWidth <= breakpoints['md-max'];
			this.isSm = window.innerWidth <= breakpoints['sm-max'];
			this.isXs = window.innerWidth <= breakpoints['xs-max'];
		},

		getIsMob(immediately) {
			if (immediately) {
				this.checkBreakpoints();
			} else {
				clearTimeout(this.isMobTimeout);
				this.isMobTimeout = setTimeout(() => {
					this.checkBreakpoints();
				}, 500);
			}
		}
	}
};
</script>