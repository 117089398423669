<template lang="pug">
.v-catalog.catalog(v-if="catalog")
	v-loader(:loading="loading")
	h1.catalog__title(v-if="catalog.title") {{catalog.title}}
	.catalog__layout
		.catalog__aside

			.catalog__aside-nav(v-if="catalog?.links?.length")
				ul.catalog__aside-nav-list
					template(v-for="(link, linkIndex) in catalog.links" :key="linkIndex")
						li.catalog__aside-nav-item.flc
							a.catalog__aside-nav-link(:href="link.link" :class="{'catalog__aside-nav-link--current': link.current}") {{link.name}}
							ul.catalog__aside-nav-list-lvl2(v-if="link.items && link.items.length")
								template(v-for="linkLvl2 in link.items")
									li.catalog__aside-nav-item-lvl2.flc
										a.catalog__aside-nav-link-lvl2(:href="linkLvl2.link" :class="{'catalog__aside-nav-link-lvl2--current': linkLvl2.current}") {{linkLvl2.name}}

			.catalog__selected(v-if="changedFilters && changedFilters.length && !isSm")
				.catalog__selected-label {{catalog.variables.SelectedFilters}}
				.catalog__filter-active-items
					v-filter-active-items(:filters="changedFilters"
							@reset-filter="resetFilter"
							@reset-all-filters="resetAllFilters"
							:variables="catalog.variables")
			.catalog__filters(v-if="!isSm")

				v-filter.catalog__filters(ref="filter"
						:filters="catalog.filters"
						@filter-changed="filterChanged"
						@filter-updated="filterUpdated"
						@request-reset="resetFilter"
						@request-reset-all="resetAllFilters")
		.catalog__layout-main
			template(v-if="$slots['catalogbanner']")
				slot(name="catalogbanner")
			.catalog__top-nav.flc
				.catalog__sort(v-if="catalog.sort")
					v-field.sort-select(:field="{type: 'select', placeholder: catalog.sort.placeholder, options: catalog.sort.options}"
							v-model="state.sort"
							@update:model-value="sortChanged")
					button.catalog__filter-btn.btn(v-if="isSm" type="button" @click="openPanel = true")
						span.catalog__filter-btn-text Фильтры
						v-svg-icon.catalog__filter-btn-icon(icon="filter-btn")
			.catalog__grid(v-if="catalog.items && catalog.items.length")
				template(v-for="(item, itemIndex) in catalog.items" :key="itemIndex")
					.catalog__col
						.catalog__item
							v-product(template="grid" :product="item")
			template(v-else)
				.catalog__message
					.catalog__message-title.h3 {{catalog.variables.NothingFound}}
					.catalog__message-text {{catalog.variables.TryBroadeningYourSearchCriteria}}
					button.catalog__message-action.btn.btn--primary(type="button" @click="resetAllFilters")
						| {{catalog.variables.ResetFilters}}
				.catalog__grid-title {{catalog.variables.PopularProducts}}
				.catalog__grid(v-if="catalog.popularItems")
					.catalog__item(v-for="(popularItem, popularItemIndex) in catalog.popularItems" :key="popularItemIndex")
						v-product(template="grid" :product="popularItem")
			.catalog__nav.flc(v-if="catalog.items && catalog.items.length && catalog.page.offset + catalog.page.limit < catalog.page.count")
				button.btn.catalog__load-btn(type="button" @click="showMore") {{catalog.variables.LoadMore}}
	teleport(to="body")
		transition(name="fade")
			.catalog__panel(v-if="openPanel")
				.catalog__panel-head
					.catalog__panel-head-title {{catalog.variables.Filters}}
					button.catalog__panel-cancel(type="button" @click="openPanel = false") {{catalog.variables.Cancel}}
				.catalog__panel-body(ref="panelBody")
					.catalog__filter-active-items(v-if="changedFilters && changedFilters.length && isSm")
						v-filter-active-items(:filters="changedFilters" @reset-filter="resetFilter" @reset-all-filters="resetAllFilters" :variables="catalog.variables")
					.catalog__filters(v-if="catalog.filters")
						v-filter.catalog__filter(ref="filterMob"
								:filters="catalog.filters"
								:links="catalog.links"
								@filter-changed="filterChanged"
								@filter-updated="filterUpdated"
								@request-reset="resetFilter"
								@request-reset-all="resetAllFilters")
				.catalog__panel-footer
					button.catalog__panel-action.btn.btn--primary(type="button" @click="openPanel = false")
						| {{catalog.variables.Show}} {{catalog?.items?.length || 0}} {{pluralizeProducts}}
</template>

<script>

import sourceMixin from '../components/mixins/v-mixin-source.vue';
import mixinIsMob from '../components/mixins/v-mixin-is-mob.vue';
import mixinFormatting from '../components/mixins/v-mixin-formatting.vue';
import scrollLock from "scroll-lock";
import {nextTick} from "vue";

export default {
	mixins: [
		sourceMixin,
		mixinIsMob,
		mixinFormatting
	],

	data() {
		return {
			catalog: null,
			page: null,
			sort: null,
			tags: {},
			state: {
				page: null,
				sort: null,
				filter: null
			},
			filtreSetCount : 0,
			changedFilters: null,
			openPanel: false
		};
	},

	watch: {
		//async openPanel(state) {
		//	if (state) {
		//		await nextTick();
		//		scrollLock.disablePageScroll(this.$refs.panelBody, this.$refs.panelSelected);
		//	} else {
		//		scrollLock.clearQueueScrollLocks();
		//		scrollLock.enablePageScroll();
		//	}
		//}
	},

	computed: {
		pluralizeProducts() {
			return this.pluralize((this.catalog?.items?.length || 0), this.catalog.variables.product, this.catalog.variables.wordEndings || ['','',''])
		},

		getFiltreSetCount() {
			if (this.state.filter) {
				return Object.keys(this.state.filter).length;
			} else {
				return null;
			}
		}
	},

	methods: {
		dataSet(data, options = {}) {
			console.log('catalog data', data);
			console.log('catalog data.remoteSet', data.remoteSet);
			if (!this.catalog) {
				setTimeout(() => {
					window.scrollTo({
						top: 0,
						behavior: "instant",
					});
				}, 300)
			}
			if (options.append && this.catalog && this.catalog.items) {
				let oldItems = this.catalog.items;
				this.catalog = data;
				this.catalog.items = oldItems.concat(this.catalog.items);
			} else {
				if (!this.remoteSet && !this.catalog && data.remoteSet){
					this.remoteSet  = data.remoteSet;
				}
				this.catalog = data;
			}

			if (this.remoteSet && this.catalog.remoteSet && this.remoteSet !== this.catalog.remoteSet){
				history.pushState(this.catalog.remoteSet, this.catalog.seo.title, this.catalog.remoteSet);
				this.remoteSet = this.catalog.remoteSet;

				if (this.catalog.seo) {
					if (document.title !== this.catalog.seo.title) {
						document.title = this.catalog.seo.title;
					}
					if (!document.querySelector('meta[name="description"]')) {
						let meta = document.createElement('meta');
						meta.name = 'description';
						document.getElementsByTagName('head')[0].appendChild(meta);
					}
					if (!document.querySelector('meta[name="keywords"]')) {
						let meta = document.createElement('meta');
						meta.name = 'keywords';
						document.getElementsByTagName('head')[0].appendChild(meta);
					}

					if (this.catalog.seo.robots && document.querySelector('meta[name="robots"]')){
						document.querySelector('meta[name="robots"]').setAttribute('content', this.catalog.seo.robots);
					} else if (this.catalog.seo.robots && !document.querySelector('meta[name="robots"]')){
						let meta = document.createElement('meta');
						meta.name = 'robots';
						document.getElementsByTagName('head')[0].appendChild(meta);
						document.querySelector('meta[name="robots"]').setAttribute('content', this.catalog.seo.robots);
					} else if (!this.catalog.seo.robots && document.querySelector('meta[name="robots"]')){
						document.querySelector('meta[name="robots"]').remove();
					}

					if (this.catalog.seo.canonical && document.querySelector('link[rel="canonical"]')){
						document.querySelector('link[rel="canonical"]').setAttribute('href', this.catalog.seo.canonical);
					} else if (this.catalog.seo.canonical && !document.querySelector('link[rel="canonical"]')){
						let meta = document.createElement('link');
						meta.rel = 'canonical';
						meta.href = this.catalog.seo.canonical;
						document.getElementsByTagName('head')[0].appendChild(meta);

					} else if (!this.catalog.seo.canonical && document.querySelector('link[rel="canonical"]')){
						document.querySelector('link[rel="canonical"]').remove();
					}

					document.querySelector('meta[name="description"]').setAttribute('content', this.catalog.seo.description);
					document.querySelector('meta[name="keywords"]').setAttribute('content', this.catalog.seo.keywords);

					if (document.querySelector('#bx-panel')) {
						document.querySelector('#bx-panel').remove();
					}

					this.$store.commit('breadcrumbs/set', this.catalog.seo.chain);
				}
			}

			this.state.sort = this.catalog.sort ? this.catalog.sort.value : null;
			this.state.page = this.catalog.page;

		},

		navigate(page) {
			this.state.page.offset = page.offset;
			this.state.page.limit = page.limit;
			this.requestDataLoad({append: page.append});
		},

		showMore() {
			this.state.page.offset = this.catalog.page.offset + this.catalog.page.limit;
			this.requestDataLoad({append: this.catalog.page.append});
		},

		sortChanged() {
			this.state.page.offset = 0;
			this.requestDataLoad();
		},

		filterUpdated(filterValue, changedFilters) {
			this.state.filter = filterValue;
			this.changedFilters = changedFilters;
			// update url except markup pages
			if (window.location.pathname.indexOf('/local/') < 0) {
				window.history.replaceState(null, null, this.catalog.remote);
			}
		},

		filterChanged(filterValue) {
			this.state.filter = filterValue;
			this.requestDataLoad();
		},

		requestDataLoad(dataSetOptions) {
			console.log("requestDataLoad")
			this.dataLoad({
				url: this.catalog.remote,
				delay: true,
				data: {
					page: this.state.page,
					sort: this.state.sort,
					filter: this.getFiltreSetCount ? this.state.filter : {empty:true}
				}
			}, dataSetOptions);
		},

		resetFilter(e) {
			if (this.isSm) {
				this.$refs.filterMob.resetFilter(e);
			} else {
				this.$refs.filter.resetFilter(e);
			}
		},

		resetAllFilters(e) {
			if (this.isSm) {
				this.$refs.filterMob.resetAll(e);
			} else {
				this.$refs.filter.resetAll(e);
			}
		}
	}
};
</script>
