<template lang="pug">
nav.v-main-nav.main-nav
	ul.main-nav__layout(ref="menuItems")
		template(v-for="(group, index) in menu" :key="`nav-group-${index}`")
			template(v-for="(link, ind) in group.items" :key="`nav-${ind}`")
				li.main-nav__cell(:class="{'main-nav__cell--short': link.short, 'main-nav__cell--phones': link.phones}")
					template(v-if='link.type !== "search"')
						a.main-nav__link(:class="{'main-nav__link--open': openMenuId === link.menuId || link.open, 'main-nav__link--search': link.type === 'search'}"
								:href="link.link"
								tabindex="0"
								@mouseenter="mouseenter(link)"
								@mouseleave="mouseleave(link)"
								@mousemove="mousemove(link)"
								@click="itemClick(link, $event)")
							template(v-if="link.type === 'search'")
								v-svg-icon.main-nav__search-link-icon(icon="search")
							template(v-else)
								.main-nav__link-main(v-if="link.text") {{link.text}}
								v-svg-icon.main-nav__link-icon(v-if="link.icon && link.items && link.items.length || link.phones" :icon="link.icon")
					template(v-else)
						span.main-nav__link(:class="{'main-nav__link--open': openMenuId === link.menuId || link.open}"
								tabindex="0"
								@mouseenter="mouseenter(link)"
								@mouseleave="mouseleave(link)"
								@mousemove="mousemove(link)"
								@click="itemClick(link, $event)")
							.main-nav__link-main(v-if="link.text") {{link.text}}
							v-svg-icon.main-nav__link-icon(v-if="link.icon && link.items && link.items.length || link.phones" :icon="link.icon")
					.main-nav__phones-dd(v-if="link.phones"
							@mouseenter="mouseenter(link)"
							@mouseleave="mouseleave(link)")
						.main-nav__phones
							a.main-nav__phone(v-for="phone in link.phones" :href="phone.link") {{phone.text}}
					.main-nav__dd(v-if="(link.items && link.items.length) || link.type === 'search'"
							:class="{'main-nav__dd--open': openMenuId === link.menuId || link.open}"
							@mouseenter="mouseenter(link)"
							@mouseleave="mouseleave(link)"
							:ref="setItemsDirection")
						.main-nav__dd-container
							.main-nav__dd-grid(v-if="link.items && link.items.length")
								.main-nav__dd-cell(v-for="(linkItem, linkItemInd) in link.items" :key="linkItemInd")
									.main-nav__dd-list
										.main-nav__dd-list-head.flc
											img.main-nav__dd-list-head-img(v-if="linkItem.icon" :src="linkItem.icon")
											template(v-if='linkItem.link')
												a.main-nav__dd-list-head-link(:href="linkItem.link" :class="{'main-nav__dd-list-head-link--link': linkItem.link}") {{linkItem.text}}
											template(v-else='')
												span.main-nav__dd-list-head-link {{linkItem.text}}

										.main-nav__dd-list-body.flc(v-if="(linkItem.items && linkItem.items.length) || linkItem.content")
											template(v-for="(linkItemCh, linkItemChInd) in linkItem.items" :key="linkItemChInd")
												template(v-if="linkItemChInd < 6")
													.main-nav__dd-list-item(:key="linkItemChInd" :class="{ 'main-nav__dd-list-item--card': linkItemCh.icon }")
														a.main-nav__dd-list-link(:href="linkItemCh.link"
																:class="{'main-nav__dd-list-link--disabled': !linkItemCh.link}")
															.main-nav__dd-list-link-visual(v-if="linkItemCh.icon")
																img.main-nav__dd-list-link-img(:src="linkItemCh.icon")
															.main-nav__dd-list-item-body
																.main-nav__dd-list-item-text {{linkItemCh.text}}
																.main-nav__dd-list-item-description {{linkItemCh.description}}
											template(v-for="(linkItemCh, linkItemChInd) in linkItem.items" :key="linkItemChInd")
												template(v-if="linkItemChInd >= 6 && linkItem.open")
													.main-nav__dd-list-item(:key="linkItemChInd" :class="{ 'main-nav__dd-list-item--card': linkItemCh.icon }")
														a.main-nav__dd-list-link(:href="linkItemCh.link"
															:class="{'main-nav__dd-list-link--disabled': !linkItemCh.link}")
															.main-nav__dd-list-link-visual(v-if="linkItemCh.icon")
																img.main-nav__dd-list-link-img(:src="linkItemCh.icon")
															.main-nav__dd-list-item-body
																.main-nav__dd-list-item-text {{linkItemCh.text}}
																.main-nav__dd-list-item-description {{linkItemCh.description}}
											.main-nav__dd-list-item(v-if="linkItem.items.length > 6 && !linkItem.open")
												button.main-nav__dd-list-link-more(type="button" @click.stop="linkItem.open = true") Еще

											.main-nav__dd-list-item(v-if='linkItem.content' v-html='linkItem.content')

		li.main-nav__cell(v-if="!isSearchHidden")
			.main-nav__link.main-nav__link--search(:class="{'main-nav__link--open': isSearchOpened}"
					tabindex="0"
					@click="clickSearchHandle")
				v-svg-icon.main-nav__search-link-icon(icon="search")
			.main-nav__dd(:class="{'main-nav__dd--open': isSearchOpened}")
				.main-nav__dd-container
					.main-nav__search
						slot(name="search")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue'
import controller from '../scripts/controller/controller.js';
import { nextTick } from 'vue'
import scrollLock from 'scroll-lock';

export default {
	mixins: [
		sourceMixin,
		isMobMixin
	],

	data() {
		return {
			menu: [],
			openMenuId: null,
			openTimeout: null,
			closeTimeout: null,
			openSearchTimeout: null,
			closeSearchTimeout: null,
			touch: controller.touch,
			isSearchOpened: false,
			isSearchHidden: false
		};
	},

	created() {
		document.addEventListener('click', this.handleClickOutside);
		document.addEventListener('keydown', this.handleEsc);
	},

	unmounted() {
		document.removeEventListener('click', this.handleClickOutside);
		document.removeEventListener('keydown', this.handleEsc);
	},

	methods: {
		dataSet(data) {
			this.isSearchHidden = data.hiddenSearch;
			this.menu.push(data);
			this.menu.sort((groupA) => groupA.type && groupA.type === 'catalog' ? -1 : 1);

			this.menu.forEach((group, groupIndex) => {
				group.items.forEach((item, itemIndex) => {
					item.menuId = groupIndex + '-' + itemIndex;
				});
			});

			controller.updateMobileNav(data);
		},

		lockScrolling() {
			scrollLock.addFillGapSelector('.page-header');
			scrollLock.disablePageScroll(document.querySelectorAll('.search-small__items'));
		},

		unlockScrolling() {
			scrollLock.enablePageScroll(document.querySelectorAll('.search-small__items'));
		},

		clickSearchHandle() {
			if (this.touch) {
				return;
			}
			this.isSearchOpened = true;
			document.body.classList.add('state--search-open');
			this.lockScrolling();
			setTimeout(() => {
				this.$nextTick(() => {
					controller.focusSearch();
				});
			}, 300);
		},

		mouseenter(item) {
			if (this.touch) {
				return;
			}
			if (this.openMenuId === item.menuId) {
				clearTimeout(this.closeTimeout);
			}
		},

		mouseleave() {
			if (this.touch) {
				return;
			}

			clearTimeout(this.openTimeout);
			this.closeTimeout = setTimeout(() => {
				this.openMenuId = null;
			}, 300);
		},

		mousemove(item) {
			if (this.touch) {
				return;
			}
			clearTimeout(this.openTimeout);
			if (item.items && item.items.length) {
				this.openTimeout = setTimeout(() => {
					clearTimeout(this.closeTimeout);
					this.openMenuId = item.menuId;
				}, 40);
			}
		},

		itemClick(item, e) {
			if (!this.touch) {
				return;
			}
			if (this.openMenuId !== item.menuId && item.items && item.items.length) {
				e.preventDefault();
				this.openMenuId = item.menuId;
			} else {
				this.openMenuId = null;
			}
		},

		handleClickOutside(e) {
			if (!this.$el.contains(e.target) && !document.body.classList.contains('state--modal-open')) {
				this.openMenuId = null;
				this.isSearchOpened = false;
				document.body.classList.remove('state--search-open');
				if (!this.isMd) {
					this.unlockScrolling();
				}
			}
		},

		handleEsc(e) {
			if (e.keyCode == 27 && this.openMenuId) {
				e.stopPropagation();
				this.openMenuId = null;
			}
		},

		async setItemsDirection(el) {
			if (el && el.classList.contains('left-direction')) return;
			await nextTick();
			if (this.$refs.menuItems?.getBoundingClientRect().left + this.$refs.menuItems?.clientWidth < el.getBoundingClientRect().left + el.clientWidth) {
				el.classList.add('left-direction')
			}
		}
	}
};
</script>
