<template lang="pug">
.v-field-range-slider.field-range-slider(:class="{'field-range-slider--disabled': field.disabled}")
	.field-range-slider__label(v-if="field.label") {{field.label}}
	.field-range-slider__controls
		.field-range-slider__ruler(ref="ruler")
		.field-range-slider__active(:style="{'left': position.min, 'right': position.maxReverse}")
		.field-range-slider__handle(:style="{'left': position.min}" @mousedown.stop="mousedown('min')" @touchstart.stop.passive="mousedown('min')" :class="{'field-range-slider__handle--z-bump': parseInt(position.min) > 98}")
		.field-range-slider__handle(:style="{'left': position.max}" @mousedown.stop="mousedown('max')" @touchstart.stop.passive="mousedown('max')")
	.field-range-slider__inputs
		.field-range-slider__input-wrap
			span.field-range-slider__input-label(v-if="field.prefixMin") {{field.prefixMin}}
			input.field-range-slider__input.field-range-slider__input--min(type="number" v-model="localValue.min" @input="handlerInput" @change="handlerChange")
		.field-range-slider__input-wrap
			span.field-range-slider__input-label(v-if="field.prefixMax") {{field.prefixMax}}
			input.field-range-slider__input.field-range-slider__input--max(type="number" v-model="localValue.max" @input="handlerInput" @change="handlerChange")
</template>

<script>
import controller from '../scripts/controller/controller';
export default {
	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: Object,
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			rulerWidth: 0,
			drag: false,
			debounceTimeout: null
		};
	},

	computed: {
		position() {
			return {
				min: (this.localValue.min - this.field.min) / (this.field.max - this.field.min) * 100 + '%',
				max: (this.localValue.max - this.field.min) / (this.field.max - this.field.min) * 100 + '%',
				maxReverse: 100 - (this.localValue.max - this.field.min) / (this.field.max - this.field.min) * 100 + '%',
			};
		}
	},

	watch: {
		modelValue() {
			this.updateLocalValue();
		}
	},

	created() {
		this.updateLocalValue();
		this.updateRulerSize();
		window.addEventListener('resize', this.updateRulerSize);
		if (controller.touch) {
			document.addEventListener('touchend', this.mouseup);
			document.addEventListener('touchmove', this.mousemove, controller.passiveEventsSupported ? {passive: true} : undefined);
		} else {
			document.addEventListener('mouseup', this.mouseup);
			document.addEventListener('mousemove', this.mousemove, controller.passiveEventsSupported ? {passive: true} : undefined);
		}
	},

	unmounted() {
		window.removeEventListener('resize', this.updateRulerSize);
		if (controller.touch) {
			document.removeEventListener('touchend', this.mouseup);
			document.removeEventListener('touchmove', this.mousemove);
		} else {
			document.removeEventListener('mouseup', this.mouseup);
			document.removeEventListener('mousemove', this.mousemove);
		}
	},

	methods: {
		updateLocalValue() {
			this.localValue = this.modelValue || {min: this.field.min || 0, max: this.field.max || 0};
		},

		updateRulerSize() {
			this.rulerWidth = this.$refs['ruler'] && this.$refs['ruler'].offsetWidth;
		},

		mousedown(handle) {
			this.drag = handle;
		},

		mouseup() {
			this.drag = false;
		},

		mousemove(e) {
			let pageX = e.pageX;
			if (e.changedTouches && e.changedTouches.length) {
				pageX = e.changedTouches[0].pageX;
			}
			if (this.drag && !this.field.disabled) {
				let ruler = this.$refs['ruler'];
				let rect = ruler.getBoundingClientRect();
				let diff = (pageX - rect.left) / rect.width;
				diff = Math.max(0, Math.min(1, diff));
				let val;
				let precision = this.field.precision || 1;
				let precisionString = precision.toString();
				let precisionPointIndex = precisionString.indexOf('.');

				if (this.drag === 'min') {
					val = Math.min(this.localValue.max, (this.field.max - this.field.min) * diff + this.field.min);
					val = Math.round(val / precision) * precision;
					// fix JS round bugs
					if (precisionPointIndex >= 0) {
						val = parseFloat(val.toFixed(precisionString.slice(precisionPointIndex).length));
					}
					this.localValue.min = val;
				} else if (this.drag === 'max') {
					val = Math.max(this.localValue.min, (this.field.max - this.field.min) * diff + this.field.min);
					val = Math.round(val / precision) * precision;
					// fix JS round bugs
					if (precisionPointIndex >= 0) {
						val = parseFloat(val.toFixed(precisionString.slice(precisionPointIndex).length));
					}
					this.localValue.max = val;
				}

				clearTimeout(this.debounceTimeout);
				this.debounceTimeout = setTimeout(this.emitInput, 1000);
			}
		},

		handlerChange() {
			this.validInput();

			if (this.localValue.max < this.localValue.min) {
				this.localValue.max = this.localValue.min
			}
			clearTimeout(this.debounceTimeout);
			this.debounceTimeout = setTimeout(this.emitInput, 1000);
		},

		handlerInput() {

			this.validInput();

			if (this.localValue.max < this.localValue.min) {
				return
			}

			clearTimeout(this.debounceTimeout);
			this.debounceTimeout = setTimeout(this.emitInput, 1000);
		},

		validInput(){
			if (this.localValue.min < this.field.min) {
				this.localValue.min = this.field.min;
			}
			if (this.localValue.min > this.localValue.max) {
				this.localValue.min = this.localValue.max;
			}
			if (this.localValue.min < this.field.min) {
				this.localValue.min = this.field.min;
			}
			if (this.localValue.max > this.field.max) {
				this.localValue.max = this.field.max;
			}
		},

		emitInput() {
			clearTimeout(this.debounceTimeout);
			this.$emit('update:model-value', this.localValue);
		}
	}
};
</script>
