<template lang="pug">
section.v-product-descr.product-descr.section.section--large-my(v-if="data")
	.container
		.section__header
			h2.product-descr__title {{data.title}}
			.product-descr__tabs.section__tabs(v-if="(data.tabs && data.fullText) || data.manual")
				template(v-if="data.tabs && data.fullText")
					template(v-for="(tab, tabIndex) in data.tabs" :key="tabIndex")
						.section__tab
							button.section__tab-btn.btn.btn--md(type="button"
									:class="{'btn--primary': tab.name === activeTab.name}"
									@click="activeTab = tab")
								| {{tab.text}}
				template(v-if="data.manual")
					.section__tab
						a.section__tab-btn.btn.btn--md(:href="data.manual" download) Скачать интрукцию
		.product-descr__main
			template(v-if="data.fullText ? data.text && activeTab && activeTab.name === 'description' : true")
				.description-block
					.description-block__main
						.description-block__main-content.text-guide(v-if="data.text" v-html="data.text")
						.description-block__actions(v-if="data.mapExist")
							a.description-block__action-btn(href="#map")
								v-svg-icon.description-block__btn-icon(icon="icon-round-map")
								span.description-block__btn-text {{data.variables.Where_can_I_buy}}
					.description-block__aside
						v-accordion(:data-source="{items: data.items}")
			template(v-if="data.fullText && activeTab && activeTab.name === 'fillDescription'")
				.full-description
					.full-description__content.text-guide(v-if="data.fullText" v-html="data.fullText")
					.full-description__action
						a.description-block__action-btn(href="#map")
							v-svg-icon.description-block__btn-icon(icon="icon-round-map")
							span.description-block__btn-text Где купить
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin,
	],

	data() {
		return {
			data: null,
			activeTab: null
		};
	},

	methods: {
		dataSet(data) {
			this.data = data;
			if (data.tabs) {
				this.activeTab = this.data.tabs[0];
			}
		}
	}
};
</script>
