<template lang="pug">
.v-needs.needs(v-if="data && data.items && data.items.length")
	.container
		.needs__container.container-inner
			.needs__main
				h2.h2 {{ data.mainTitle }}
				.needs__main-content
					.needs__chips(ref="tabs")
						template(v-for="(chip, index) in data.items" :key="chip.id")
							button.btn.needs__chip(v-if="chip.tabName" :class="{ 'active': activeIndex === index }" @click="toggleIndex(index)")
								v-svg-icon.needs__chip-icon(v-if="chip.icon" :icon="chip.icon")
								span.needs__chip-text {{ chip.tabName }}
			.needs-slider
				v-swiper.needs-slider-slider(
					v-bind="options"
					:id="uniqueId"
					@swiper="onSwiper"
					@slideChange="onSlideChange"
				)
					v-swiper-slide(v-for="(slide, index) in data.items" :key="slide.id || index")
						.needs-slider__slide(v-if="slide.text || slide.title")
							.h4.needs-slider__index 0{{ index + 1 }}
							.needs-slider__keyword {{ slide.keyword }}
							h4.h4.needs-slider__title {{ slide.title }}
							.needs-slider__body
								.needs-slider__image-wrap(v-if="slide.image && slide.image.src")
									img.image--full(:src="slide.image.src" :alt="slide.image.alt")
								.needs-slider__text {{ slide.text }}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue'
import uniqueId from '../scripts/helpers/uniqueId.js'
import { Swiper, SwiperSlide } from 'swiper/vue'
import {nextTick} from "vue";

export default {
	components: {
		'v-swiper-slide': SwiperSlide,
		'v-swiper': Swiper
	},

	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			uniqueId: uniqueId('swiper-'),
			activeIndex: 0,
			options: {
				slidesPerView: 1,
				spaceBetween: 16,
				grabCursor: true,
				breakpoints: {
					1024: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 16,
					},
					320: {
						slidesPerView: 'auto',
						spaceBetween: 0,
					}
				}
			},
			swiper: null
		};
	},

	watch: {
		activeIndex() {
			this.scrollToActive();
		}
	},

	methods: {
		dataSet(data) {
			this.data = data
		},

		toggleIndex(index) {
			this.activeIndex = index
			this.swiper?.slideTo(index, 500)
		},

		onSwiper(swiper) {
			this.swiper = swiper
		},

		onSlideChange() {
			if (this.swiper) {
				this.activeIndex = this.swiper.activeIndex
			}
		},

		async scrollToActive() {
			await nextTick()
			if (this.$refs.tabs) {
				let active = this.$refs.tabs.querySelector('.active');
				this.$refs.tabs.scrollTo({
					left: active.offsetLeft - 12,
					behavior: "smooth"
				})
			}
		}
	}
};
</script>