<template lang="pug">
.v-field-text.field-text(:class="{'field-text--placeholder-state': checkPlaceholderState, 'field-text--focus': hasFocus, 'disabled': field.disabled, 'field-text--error': field.errors && field.errors.length, 'field-text--textarea': field.type === 'textarea'}")
	textarea.field-input(
			v-if="field.type === 'textarea'"
			:class="{'field-input--sm': field.size === 'sm'}"
			:inputmode="field.inputmode"
			:disabled="field.disabled"
			:placeholder="field.placeholder"
			v-model="localValue"
			@input="handleInput"
			@focus="hasFocus = true"
			@blur="hasFocus = false"
			:id="uniqueId")
	input.field-input(
			v-else-if="field.mask"
			:class="{'field-input--sm': field.size === 'sm'}"
			:type="computedType"
			:inputmode="field.inputmode"
			:disabled="field.disabled"
			placeholder=" "
			:value="localValue"
			:id="uniqueId"
			v-imask="field.mask"
			@accept="onAccept"
			@complete="onComplete"
			@focus="hasFocus = true"
			@blur="hasFocus = false")
	input.field-input(
			v-else=""
			ref="input"
			:class="{'field-input--sm': field.size === 'sm'}"
			:type="computedType"
			:inputmode="field.inputmode"
			:disabled="field.disabled"
			:placeholder="isSm ? field.placeholderMob : field.placeholder"
			v-model="localValue"
			@input="handleInput"
			@keyup="handleKeyup"
			@focus="hasFocus = true"
			@blur="hasFocus = false"
			:id="uniqueId")
	label.field-label(v-if="field.label" :for="uniqueId") {{field.label}}
		span.field-required(v-if="field.required") *
</template>

<script>
import uniqueId from '../scripts/helpers/uniqueId.js';
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue';

export default {
	mixins: [
		isMobMixin
	],

	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: [String, Number],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			savedValue: null,
			unmaskedValue: null,
			fullUnmaskedValueLength: 0,
			uniqueId: uniqueId('field-text_'),
			hasFocus: false,
		};
	},

	computed: {
		computedType() {
			if (!this.field.type || this.field.type === 'number') {
				return 'text';
			}
			return this.field.type;
		},

		isFieldNumeric() {
			return !!(this.field.type === 'number' || this.field.inputmode === 'numeric' || this.field.inputmode === 'decimal');
		},

		checkPlaceholderState() {
			return this.field.type !== 'search' && !this.hasFocus && (this.localValue === null || this.localValue === '' || this.localValue.length === 0);
		}
	},

	watch: {
		modelValue: {
			immediate: true,
			handler() {
				if (this.isFieldNumeric && this.modelValue) {
					this.localValue = this.savedValue = this.modelValue.toString().replace('.', ',');
				} else {
					this.localValue = this.savedValue = this.modelValue;
				}
			}
		}
	},

	methods: {
		focus() {
			console.log(1);
			this.$refs['input'].focus();
		},

		onAccept(e) {
			if (e.detail.unmaskedValue.length !== this.fullUnmaskedValueLength) {
				this.handleInputTel(e);
			}
		},

		onComplete(e) {
			this.handleInputTel(e);
		},

		handleKeyup() {
			if (this.isFieldNumeric && this.savedValue) {
				this.localValue = this.savedValue.replace('.', ',');
			}
		},

		handleInputTel(e) {
			this.unmaskedValue = e.detail.unmaskedValue;
			this.fullUnmaskedValueLength = this.unmaskedValue.length;
			this.$emit('update:model-value', this.unmaskedValue);
		},

		handleInput() {
			if (this.isFieldNumeric) {
				if (!this.localValue || /^-?(?:\d+)(?:(\.|,)(\d+)?)?$/.test(this.localValue)) {
					this.savedValue = this.localValue;
				} else {
					this.localValue = this.savedValue;
				}
				this.$emit('update:model-value', this.localValue ? parseFloat(this.localValue.toString().replace(',', '.')) : this.localValue, this.field);
			} else {
				this.$emit('update:model-value', this.localValue, this.field);
			}
		}
	}
};
</script>
