<template lang="pug">
.v-field-select.field-select(:class="{'field-select--open': open, 'field-select--sm': field.size === 'sm'}")
	label.field-select__label(v-if="field.label" :for="uniqueId") {{field.label}}
	template(v-if="field.searchable || field.searchAjax")
		.field-select__field-text.field-text.field-select--search
			input.field-select__field-text-input.field-input(
				ref="input"
				type="text"
				:placeholder="field.placeholder"
				:inputmode="field.inputmode"
				:disabled="field.disabled"
				v-model="selectValueText"
				@input="searchAjaxCheck"
				@focus="inputFocus"
				@blur="inputBlur"
				:id="uniqueId"
				@keyup="foundSelectValue")
			button.field-select__shevron(type="button" @click="toggleOpen" :disabled="field.disabled")
				v-svg-icon.field-select__btn-arrow(icon="chevron-down-sm")
		.field-select__list.field-select__list--search.scrollbar(@click="open = false")
			.field-select__list-container
				template(v-for="(option, optionIndex) in field.options" :key="optionIndex")
					template(v-if="isFoundSelectText(option.text)")
						button.field-select__list-item(type="button" @click.stop="handleClickSearchable($event, option)" :disabled="field.disabled") {{option.text}}
							v-svg-icon.field-select__check-list-icon(icon="check-icon")
				.field-select__list-item.field-select__list-item--message(v-if='selectValueText && selectValueText.length > 0 && !isSelectValueFound') Ничего не найдено
	template(v-else)
		button.field-select__btn(type="button" @click="open = !open" :id="uniqueId")
			span.field-select__btn-placeholder(v-if="!activeOption && field.placeholder" key="placeholder") {{field.placeholder}}
			span.field-select__btn-text(v-else-if="activeOption" key="text") {{activeOption.text}}
			v-svg-icon.field-select__btn-arrow(icon="chevron-down-sm")
		transition(name="dropdown")
			.field-select__list(v-if="field && open" @click="open = false;")
				transition(name="dropdown-inner")
					.field-select__list-container(v-if="open")
						button.field-select__list-item(type="button" v-for="option in field.options" :class="{'selected': localValue === option.value}" @click="localValue = option.value; handleInput();") {{option.text}}
</template>

<script>
import sourceMixin from 'components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	mixins: [
		sourceMixin
	],

	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: [Object, String, Number, Boolean, Symbol, Date],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			options: null,
			timerId: 0,
			open: false,
			uniqueId: uniqueId('field-select_'),
			selectValueText: '',
			hasFocus: false,
			isSelectValueFound: false,
			selectValue: null
		};
	},

	computed: {
		activeOption() {
			if (this.field && this.field.options && this.field.options.length) {
				return this.field.options.find(option => option.value === this.localValue);
			}
			return null;
		}
	},

	watch: {
		modelValue() {
			this.updateLocalValue();
		}
	},

	created() {
		this.updateLocalValue();
		if (this.field.searchable && this.localValue) {
			this.selectValueText = this.activeOption ? this.activeOption.text || this.activeOption.value : '';
		}
		document.addEventListener('click', this.handleClickOutside);
		document.addEventListener('keydown', this.handleEsc);
	},

	unmounted() {
		document.removeEventListener('click', this.handleClickOutside);
		document.removeEventListener('keydown', this.handleEsc);
	},

	methods: {
		updateLocalValue() {
			this.localValue = this.modelValue;
		},

		dataSet(data) {
			if (!data) {
				return;
			}
			this.foundSelectValue();
			if (data.searchResult) {
				this.field.options = this.options = data.searchResult;
				this.open = true;
			} else if (data.options) {
				this.options = data.options;
			}
			this.updateLocalValue();
		},

		toggleOpen() {
			if (this.field.disabled) {
				return false;
			}
			this.open = !this.open;
			this.selectValueText = '';
			if ((this.field.searchable || this.field.searchAjax) && !this.open && this.localValue) {
				this.updateSearchInputText();
			}
		},

		updateSearchInputText() {
			this.localValueText = '';
			if (!this.localValueText) {
				this.localValueText = this.selectValueText ? this.selectValueText : '';
			}
			if (!this.open) {
				this.selectValueText = this.localValueText;
			}
		},

		searchAjaxCheck() {
			if (this.field.searchAjax) {
				this.open = false;
				clearTimeout(this.timerId);
				this.timerId = setTimeout(this.searchAjax, 500);
			} else {
				this.open = true;
			}
		},

		searchAjax() {
			if (!this.selectValueText || this.selectValueText.trim().length < 3) {
				return
			}
			this.localValue = [];
			this.localValueText = '';
			this.dataLoad({
				url: this.field.searchAjax.remote,
				data: {
					bxajaxid: this.field.searchAjax.bxajaxid,
					ajaxSearch: this.selectValueText.trim(),
					fieldCode: this.field.code
				},
				delay: false
			}, null)
		},

		handleClickSearchable(e, option) {
			this.localValue = option.value;
			this.handleInput();
			this.open = false;
			this.selectValueText = option.text;
		},

		foundSelectValue() {
			let result = false;
			if (this.field && this.field.options && this.field.options.length) {
				for (let i = 0; i < this.field.options.length; i++) {
					if (this.isFoundSelectText(this.field.options[i].text)) {
						result = this.field.options[i].text;
						this.isSelectValueFound = true;
						break;
					}
				}
				console.log('result', result)
				if (result) {
					this.isSelectValueFound = true;
				} else {
					this.isSelectValueFound = false;
				}
			}
			return result;
		},

		isFoundSelectText(text = '') {
			if (this.field.searchAjax) {
				return true;
			}
			let vm = this;
			let result = false;
			let selectValueText = vm.selectValueText;
			if (!selectValueText) {
				selectValueText = '';
			}
			if (text.toLowerCase().indexOf(selectValueText.toLowerCase()) !== -1) {
				result = true;
			} else {
				result = false;
			}
			return result;
		},

		handleInput() {
			console.log('update', this.localValue)
			this.$emit('update:model-value', this.localValue);
		},

		inputFocus() {
			this.hasFocus = true;
		},

		inputBlur() {
			this.hasFocus = false;
		},

		handleClickOutside(e) {
			if (!this.$el.contains(e.target)) {
				this.open = false;
			}
		},

		handleEsc(e) {
			if (e.keyCode == 27 && this.open) {
				e.stopPropagation();
				this.open = false;
			}
		}
	}
};
</script>
