<template lang="pug">
.v-certification.certification
	.certification__container.container
		.certification__wrap
			.certification__items
				template(v-for="(item, index) in certification.items" :key="index")
					.certification__item.flc
						h2.certification__title.h3(v-if="item.name") {{item.name}}
						.certification__grid-wrap.flc
							.certification__grid(v-if="item.images && item.images.length")
								template(v-for="(image, imageIndex) in item.images" :key="imageIndex")
									.certification__col
										.certification__image-wrap(@click="openModal(image)")
											img.certification__image(:src="image" alt="" loading="lazy")
						.certification__action(v-if="item.link")
							a.certification__link.btn(:href="item.link.url" download)
								span.certification__link-text {{item.link.text}}
								span.certification__file-name &nbsp;{{item.link.fileName}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import controller from '../scripts/controller/controller.js';
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			certification: null,
			uniqueId: uniqueId('modal-')
		};
	},

	methods: {
		openModal(image) {
			controller.openModal({
				className: 'modal--full',
				content: `
					<div class="modal-window" role="dialog" aria-labelledby="modal-window-${uniqueId()}">
						<button class="modal-window__close js-close-modal" type="button">
							<svg class="svg-icon modal-window__close-icon">
								<use href="/local/assets/dist/icons/sprite.svg#svg-icon-modal-close"></use>
							</svg>
						</button>
						<div class="modal-window__body">
							<img src="${image}" alt="" class="certificate-image" alt="">
						</div>
					</div>`
			});
		},

		dataSet(data) {
			this.certification = data;
		}
	}
};

</script>