import { createApp } from 'vue';
import store from 'scripts/model/model';
import components from 'scripts/components';
import directives from 'scripts/directives';

const createAppExt = (el) => {
	let app = createApp({
		mounted() {
			this.$el.parentNode.replaceWith(this.$el);
		}
	});

	app.config.globalProperties.$log = console.log;

	app.use(store);

	for (let name in components) {
		app.component(name, components[name]);
	}

	for (let name in directives) {
		app.directive(name, directives[name]);
	}

	if (el) {
		app.mount(el);
	}

	return app;
};

export { createAppExt };