<template lang="pug">
nav.v-mobile-nav.mobile-nav
	transition(name="fade" @click="close")
		.mobile-nav__overlay(v-if="openPanels.length")
	ul.mobile-nav__panels(v-if="flatMenu && flatMenu.length")
		li.panel(v-for="panel in flatMenu" :class="{'panel--open': isPanelOpen(panel)}")
			.mobile-header
				.mobile-header__apper
					template(v-if="$slots['logo-mobile']")
						.mobile-header--logo
							slot(name="logo-mobile")
					button.mobile-header__btn-close(type="button" @click="close")
						v-svg-icon.panel-head__btn-icon(icon="close")
				template(v-if="$slots['switcher-mobile']")
					.switcher--light
						slot(name="switcher-mobile")
			.panel__head.panel-head(v-if="panel.parentText")
				button.panel-head__btn.panel-head__btn--back(type="button" v-if="panel.parentId !== '0'" @click="back")
					v-svg-icon.panel-head__btn-icon(icon="back")
				a.panel-head__title(:href="panel.link") {{ panel.parentText }}
			.mobile-nav__search-wrap(v-if="!panel.parentText")
				slot(name="search")

			ul.panel__body(data-scroll-lock-scrollable ref="panelBody")
				li.panel__item(v-if="panel.mobileName")
					a.panel__btn(
							:href="panel.link"
							tabindex="0")
						.panel__btn-main(v-if="panel.mobileName") {{panel.mobileName}}
				li.panel__item(v-for="item in panel.items")
					a.panel__btn(
							:href="item.link"
							:data-modal-url="item.modalLink"
							:data-modal-data="item.modalData"
							:class="{'js-open-modal': item.modalLink || item.modalData}"
							tabindex="0"
							@click="itemClick(item, $event)")
						.panel__btn-main(v-if="item.text") {{item.text}}
						v-svg-icon.panel__btn-arrow(v-if="item.hasItems" icon="chevron-right-sm")
</template>

<script>
import breakpoints from '../scripts/breakpoints.js';
import controller from '../scripts/controller/controller.js';
import scrollLock from "scroll-lock"; // https://github.com/FL3NKEY/scroll-lock/

export default {
	data() {
		return {
			menu: [],
			openPanels: [],
			openTimeout: null,
			closeTimeout: null,
			touch: controller.touch,
			flatMenu: null
		};
	},

	created() {
		this.dataSet(controller.mobileNavData);
		controller.events.on('mobile-nav-updated', this.dataSet);
		controller.events.on('mobile-nav-open', this.open);
		controller.events.on('mobile-nav-close', this.close);
		window.addEventListener('resize', this.handleResize);
		document.addEventListener('keydown', this.handleEsc);
		controller.events.on('search-small-open', this.addScrollableSelectors);
	},

	unmounted() {
		window.removeEventListener('resize', this.handleResize);
		document.removeEventListener('keydown', this.handleEsc);
	},

	methods: {
		addScrollableSelectors() {
			this.$nextTick(() => {
				scrollLock.addScrollableSelector('.search-small__tabs-wrapper');
				scrollLock.addScrollableSelector('.mobile-nav__search-wrap');
			});
		},

		updateFlatMenu() {
			let result = [];
			if (this.menu) {
				let firstPanel = {
					text: '',
					itemId: '0',
					items: this.menu.reduce((acc, group) => group.items ? acc.concat(group.items) : acc, [])
				};
				let flattenMenuRecursive = (parentItem) => {
					if (parentItem.items && parentItem.items.length) {
						let panel = {
							parentId: parentItem.itemId,
							parentText: parentItem.text,
							link: parentItem.link,
							mobileName: parentItem.mobileName,
							items: []
						};
						parentItem.items.forEach((item, itemIndex) => {
							item.itemId = parentItem.itemId + '-' + itemIndex;
							panel.items.push({...item, menuId: null, items: null, hasItems: !!item.items && !!item.items.length});
							flattenMenuRecursive(item);
						});
						result.push(panel);
					}
				};
				flattenMenuRecursive(firstPanel);
				result.reverse();
			}
			this.flatMenu = result;
		},

		dataSet(data) {
			this.menu = data;
			if (this.menu) {
				this.menu.sort((groupA, groupB) => {
					const order = {
						'catalog': -1,
						'menu': 2,
						'top-menu': 3,
						'user': 4
					};
					return (order[groupA.type] || 0) - (order[groupB.type] || 0);
				});
			}
			this.updateFlatMenu();
		},

		open() {
			scrollLock.clearQueueScrollLocks();
			scrollLock.disablePageScroll(document.querySelectorAll('.panel__body'));
			this.openPanels = ['0'];
		},

		close() {
			scrollLock.enablePageScroll(document.querySelectorAll('.panel__body'));
			controller.closeSearchSmall();
			this.openPanels = [];
		},

		back() {
			this.openPanels.pop();
			if (!this.openPanels.length) {
				this.close();
			}
		},

		isPanelOpen(panel) {
			return this.openPanels.indexOf(panel.parentId) >= 0;
		},

		itemClick(item, e) {
			if (item.hasItems) {
				e.preventDefault();
				this.openPanels.push(item.itemId);
			}
		},

		handleResize() {
			if (window.innerWidth >= breakpoints['sm-max'] && this.openPanels && this.openPanels.length) {
				this.close();
			}
		},

		handleEsc(e) {
			if (e.keyCode == 27 && this.openPanels.length) {
				e.stopPropagation();
				this.close();
			}
		}
	}
};
</script>
