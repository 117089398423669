<template lang="pug">
.v-product-slider.product-slider
	.product-slider__container.container
		.product-slider__slider-wrap
			.product-slider__banner.hide__mobile(v-if="slider.banner && slider.hideBannerMob" v-html="slider.banner")
			.product-slider__banner(v-else-if="slider.banner" v-html="slider.banner")
			.product-slider__slider(v-if="slider && slider.items && slider.items.length" :class="{'product-slider__slider--half': slider.banner}")
				button.hero-slider__arrow.hero-slider__arrow--prev(type="button" ref="prev")
					v-svg-icon.hero-slider__icon(icon="slider-left")
				button.hero-slider__arrow.hero-slider__arrow--next(type="button" ref="next")
					v-svg-icon.hero-slider__icon(icon="slider-right")
				v-swiper-slider(:options="computedOptions"
						:navigation="navigation"
						:key="uniqueId"
						ref="swiperSlider")
					v-swiper-slide.product-slider__slide(v-for="(item, index) in slider.items" :key="item.id || index")
						v-product(v-if="slider.type === 'products'" :product="item")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';
import {SwiperSlide} from 'swiper/vue';

export default {
	components: {
		'v-swiper-slide': SwiperSlide
	},

	mixins: [
		sourceMixin
	],

	props: {
		options: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			slider: {},
			uniqueId: uniqueId('swiper-'),
			navigation: null,
			options2: {
				slidesPerView: 'auto',
				spaceBetween: 12,
				grabCursor: true,
				arrows: true,
				loop: true,
				breakpoints: {
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 2,
					},
					1280: {
						slidesPerView: 2,
						spaceBetween: 12
					}
				}
			}
		};
	},

	computed: {
		computedOptions() {
			if (this.slider.banner) {
				return this.options2;
			} else {
				let result = {
					slidesPerView: 'auto',
					spaceBetween: 12,
					grabCursor: true,
					arrows: true,
					loop: false,
					breakpoints: {
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 4,
						},
						1280: {
							slidesPerView: 4,
							spaceBetween: 12
						}
					}
				};
				if (this.options) {
					Object.keys(this.options).forEach((key) => {
						if (typeof this.options[key] !== 'undefined') {
							result[key] = this.options[key];
						}
					});
				}
				return result;
			}
		}
	},

	methods: {
		dataSet(data) {
			this.slider = data;
			this.$nextTick(()=>{
				this.navigation = {
					prevEl: this.$refs['prev'],
					nextEl: this.$refs['next']
				}
			});
		}
	}
};
</script>
