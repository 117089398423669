<template lang="pug">
.v-brands.brands(v-if="data" :class="{'brands--cursor-default': data.items.length <= slidesLimit}")
	.brands__items(v-if="isSm")
		img.brands__item(v-for="(slide, index) in data.items" :src="slide.image.src" :alt="slide.image.alt" :key="index")
	v-base-slider(v-else :options="options" :slides-limit="slidesLimit" :items-length="data.items.length")
		v-swiper-slide(v-for="(slide, index) in data.items" :key="slide.id || index")
			.brands__item-wrap
				img.brands__item(:src="slide.image.src" :alt="slide.image.alt")
</template>

<script>

import sourceMixin from '../components/mixins/v-mixin-source.vue'
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue'

import {SwiperSlide} from 'swiper/vue';

export default {
	components: {
		'v-swiper-slide': SwiperSlide
	},

	mixins: [
		sourceMixin,
		isMobMixin
	],

	data() {
		return {
			data: null,
			options: null,
			slidesLimit: 4
		}
	},

	methods: {
		dataSet(data) {
			this.data = data;
			this.options = {
				slidesPerView: 4,
				spaceBetween: 12,
				allowTouchMove: this.data.items.length > 4
			}
		}
	}
}

</script>