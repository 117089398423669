<template lang="pug">
.v-text-slider.text-slider(v-if="slider && slider.items && slider.items.length")
	.text-slider__icon

	v-swiper-slider.text-slider__slider(
		:options="options"
		:pagination="options.pagination"
		:key="uniqueId"
		:ref="`swiperSlider-${uniqueId}`"
	)
		v-swiper-slide.text-slider__slide(v-for="(slide, index) in computedItems" :key="slide.id || index")
			.text-slider__text {{slide.text}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';
import {SwiperSlide} from 'swiper/vue';

export default {
	components: {
		'v-swiper-slide': SwiperSlide
	},

	mixins: [
		sourceMixin
	],

	data() {
		return {
			slider: {},
			options: {
				autoplay: {
					delay: 3000,
				},
				direction: 'vertical',
				simulateTouch: false,
				slidesPerView: 1,
				spaceBetween: 16,
				pagination: {
					el: '.text-slider__icon',
					type: 'bullets',
					dynamic: false
				},
			},
			uniqueId: uniqueId('swiper-')
		};
	},

	computed: {
		computedItems() {
			return this.slider?.items.slice(0,4)
		}
	},

	methods: {
		dataSet(data) {
			this.slider = data;
		}
	}
};
</script>