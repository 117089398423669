<template lang="pug">
.v-btn-top.btn-top(:class="{'btn-top--visible': isVisible, 'btn-top--shifted': isShifted}")
	.btn-top__container.container
		.btn-top__wrap
			button.btn-top__btn(type="button" @click.stop="scrollToTop")
				v-svg-icon.btn-top__icon(icon="slider-right")
</template>

<script>
import controller from "../scripts/controller/controller.js";

export default {
	data() {
		return {
			isVisible: false,
			isShifted: false,
		};
	},

	mounted() {
		window.addEventListener('scroll', this.setVisibility, controller.passiveEventsSupported ? {passive: true} : undefined);
	},

	methods: {
		setVisibility() {
			if (window.pageYOffset > 1000) {
				this.isVisible = true;
			} else {
				this.isVisible = false;
			}
			if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 50) {
				this.isShifted = true;
			} else {
				this.isShifted = false;
			}
		},

		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}
};
</script>
