<template lang="pug">
.modal-window(role="dialog" :aria-labelledby="uniqueId")

	button.modal-window__close.js-close-modal(type='button')
		v-svg-icon.modal-window__close-icon(icon='close')
	.modal-window__header(v-if='0')
		.h2(v-if="title" :id="uniqueId") {{title}}
	.modal-window__body
		slot
</template>

<script>
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		title: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			uniqueId: uniqueId('modal-'),
			active: true
		};
	}
};
</script>
