<template lang="pug">
.v-field-text.field-phone
	label.field-label(v-if="field.label" :for="uniqueId") {{field.label}}
	span.field-phone__required(v-if="field.required") *
	.field-phone__select
		button.field-phone__btn(type="button" @click="open = !open" :class="{'field-phone__btn--open': open}")
			.field-phone__btn-text {{active?.text}}
			v-svg-icon.field-phone__btn-arrow(icon="chevron-down-round")
		.field-phone__list(v-if="field.options && open")
			.field-phone__list-item(v-for="option in field.options" @click="optionClick(option)")
				span.field-phone__list-item-text {{option.text}}
	input.field-input(v-if="active"
			:key="active.mask"
			type="text"
			inputmode="numeric"
			:disabled="field.disabled"
			:placeholder="active.mask.replaceAll('0', '_')"
			:value="localValue"
			:id="uniqueId"
			v-imask="active"
			@accept="onAccept"
			@complete="onComplete")
</template>

<script>
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: [String, Number],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			uniqueId: uniqueId('field-text_'),
			open: false,
			active: {
				mask: '',
				lazy: false
			},
		};
	},

	mounted() {
		this.active = this.field.options[0]
	},

	created() {
		document.addEventListener('click', this.handleClickOutside);
	},

	unmounted() {
		document.removeEventListener('click', this.handleClickOutside);
	},

	watch: {
		modelValue: {
			immediate: true,
			handler() {
				this.localValue = this.modelValue;
			}
		}
	},

	methods: {
		onAccept(e) {
			const maskRef = e.detail;
			this.localValue = maskRef.value;
			this.handleInputTel(e);
		},

		onComplete(e) {
			this.handleInputTel(e);
		},

		handleInputTel(e) {
			this.$emit('update:model-value', this.localValue, this.field);
		},

		handleInput() {
			this.$emit('update:model-value', this.localValue, this.field);
		},

		handleClickOutside(e) {
			if (!this.$el.contains(e.target)) {
				this.open = false;
			}
		},

		optionClick(option) {
			this.localValue = null;
			this.active = option;
			this.open = false;
		}
	}
};
</script>
