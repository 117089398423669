<template lang="pug">
.blue-tabs.scrollbar-none(v-if="data")
	template(v-for="(tab, tabIndex) in data.items" :key="tabIndex")
		.blue-tabs__item-wrap
			template(v-if="tab.active")
				.blue-tabs__item.btn(:class="{'blue-tabs__item--active': tab.active}") {{tab.name}}
			template(v-else)
				a.blue-tabs__item.btn(:href="tab.url" :class="{'blue-tabs__item--active': tab.active}") {{tab.name}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue'

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null
		}
	},

	methods: {
		dataSet(data) {
			this.data = data;
		}
	}
}

</script>