export const bottomMenuMore = (scope) => {
	let $buttons = scope.querySelectorAll('.js-bottom-menu-more-btn');
	$buttons.forEach($btn => {
		$btn.removeEventListener('click', toggleBottomMenuMore);
		$btn.addEventListener('click', toggleBottomMenuMore);
	})
}

function toggleBottomMenuMore() {
	let $main = this.closest('.js-bottom-menu-more');
	let $spoiler = $main?.querySelector('.js-bottom-menu-spoiler');
	if ($main && $spoiler) {
		if ($spoiler.classList.contains('open')) {
			$spoiler.classList.remove("open");
		} else {
			$spoiler.classList.add("open");
		}
	}
}