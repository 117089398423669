export const toggleOpen = scope => {

    if (!scope) return

    let buttons = scope.querySelectorAll('.js-toggle-open-btn');

    buttons.forEach(btn => {
        btn.removeEventListener('click', toggleOpenFn);
        btn.addEventListener('click', toggleOpenFn);
    })

    if (buttons?.length) {
        document.body.addEventListener('click', closeOpenFn);
    }
}

function toggleOpenFn() {
    let main = this.closest('.js-toggle-open');
    if (main.classList.contains('open')) {
        main.classList.remove('open');
    } else {
        main.classList.add('open');
    }
}

function closeOpenFn(e) {
    let blocks = document.querySelectorAll('.js-toggle-open');
    blocks.forEach(block => {
        if (block.dataset.closeOutsideClick && !block.contains(e.target)) {
            block.classList.remove('open');
        }
    })
}