<template lang='pug'>
.v-switcher.switcher
	.switcher__wrap(v-if="data && data.items && data.items.length")
		button.switcher-tab(v-for="tab in data.items" :class="{ 'active': active.link && tab.link === active.link }" @click="active = tab")
			.switcher-tab__link(:aria-label="tab.text") {{ tab.text }}
			.switcher-tab__bg(@transitionend="redirectHandler")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue'

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			active: {}
		};
	},

	methods: {
		dataSet(data) {
			this.data = data;
			if (data.items) {
				this.active = data.items.find(item => item.active);
			}
		},

		toggleIndex(index) {
			this.activeIndex = index
		},

		redirectHandler() {
			window.location.href = this.active.link;
		}
	}
};
</script>