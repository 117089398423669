<template lang="pug">
.v-field-tel.field-tel(v-if="field" dir="ltr")
	.field-tel__wrap
		.field-tel__select(v-if="field.masks && field.selectField")
			v-field-select(:field="field.selectField" :model-value="selectLocalValue" @update:model-value="handleInputSelect" :show-clean="false")
		.field-tel__field-text

			template(v-if="field.masks")
				input.field-input(type="tel"
						:inputmode="field.inputmode"
						:disabled="field.disabled"
						:placeholder="computedPlaceholder"
						:maxlength="field.maxlength || 50"
						:value="localValue"
						:id="uniqueId"
						v-imask="computedMask"
						@accept="onAccept"
						@complete="onComplete"
						@focus="hasFocus = true"
						@blur="checkValue"
						ref="maskRef")
				label.field-label(v-if="field.label" :for="uniqueId") {{field.label}}
					span.field-required(v-if="field.required") *
			template(v-else)
				input.field-input(type="tel"
						:inputmode="field.inputmode"
						:disabled="field.disabled"
						:placeholder="field.placeholder"
						:maxlength="field.maxlength || 50"
						:value="localValue"
						@focus="hasFocus = true"
						@blur="hasFocus = false")
				label.field-label(v-if="field.label" :for="uniqueId") {{field.label}}
					span.field-required(v-if="field.required") *
</template>
"
<script>
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		errors: {
			type: [Array, Boolean],
			default: null,
		},

		modelValue: {
			type: [String, Number],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			savedValue: null,
			unmaskedValue: null,
			fullUnmaskedValueLength: 0,
			uniqueId: uniqueId('field-text_'),
			hasFocus: false,
			htmlDir: '',
			isRtl: null,
			selectLocalValue: null,
			isComplete: false
		};
	},

	computed: {
		computedPlaceholder() {
			return this.field.masks.find(item => item.id === this.selectLocalValue).placeholder || "";
		},

		computedMask() {
			return {
				"mask": this.field.masks.find(item => item.id === this.selectLocalValue).mask || "",
				"lazy": true
			}
		},

		activeOption() {
			if (this.field && this.field.selectField.options && this.field.selectField.options.length) {
				return this.field.selectField.options.find(option => option.value === this.localValue);
			}
			return null;
		},

		checkPlaceholderState() {
			return !this.hasFocus && (this.localValue === null || this.localValue === '' || this.localValue.length === 0);
		},
	},

	watch: {
		modelValue: {
			immediate: true,
			handler() {
				if (this.isFieldNumeric && this.modelValue) {
					this.localValue = this.savedValue = this.modelValue.toString().replace('.', ',');
				} else {
					this.localValue = this.savedValue = this.modelValue;
				}
			}
		}
	},

	created() {
		this.htmlDir = document.documentElement.getAttribute('dir');
		this.isRtl = this.htmlDir === 'rtl';
		if (this.field.selectField) {
			this.selectLocalValue = this.field.selectField.value;
		}
	},

	methods: {
		checkValue() {
			this.hasFocus = false;
			if (!this.isComplete) {
				this.localValue = null;
			}
		},

		onAccept(e) {
			this.isComplete = false;
			if (e.detail.unmaskedValue.length !== this.fullUnmaskedValueLength) {
				this.handleMasketInput(e);
			}
		},

		onComplete(e) {
			this.isComplete = true;
			this.handleMasketInput(e);
		},

		handleMasketInput(e) {
			this.unmaskedValue = e.detail.unmaskedValue;
			this.fullUnmaskedValueLength = this.unmaskedValue.length;
			this.$emit('update:model-value', this.unmaskedValue);
		},

		handleInputSelect(value) {
			this.selectLocalValue = value;
		},

		handleInput() {
			this.$emit('update:model-value', this.localValue);
		}
	}
};
</script>
