<template lang="pug">
.v-available-table.available-table(v-if="data")
	.available-table__header(v-if="data.itemsHeader" )
		.available-table__row--head
			.available-table__columns
				.available-table__column(v-if="data.itemsHeader.name") {{ data.itemsHeader.name }}
				.available-table__column(v-if="data.itemsHeader.price") {{ data.itemsHeader.price }}
				.available-table__column(v-if="data.itemsHeader.address") {{ data.itemsHeader.address }}
				.available-table__column(v-if="data.itemsHeader.phone") {{ data.itemsHeader.phone }}
	.available-table__body(v-if="data.items" )
		.available-table__row(v-for="(item, index) in data.items" :key="index")
			.available-table__columns
				.available-table__column(v-if= "item.name") {{ item.name }}
				.available-table__column(v-if= "item.price") {{ item.price }}
				.available-table__column(v-if= "item.address") {{ item.address }}
				.available-table__column(v-if= "item.phone && Array.isArray(item.phone)")
					template(v-for="(phone, index) in item.phone" :key="index")
						a.available-table__link(:href="phone?.link") {{phone.text}}
						//component(:is="phone?.link ? 'a' : 'div'" :class="phone.link ? 'available-table__link' : 'available-table__text'" :href="phone.link ? phone.link : null") {{phone.text}}
				.available-table__column(v-else-if= "item.phone && typeof item.phone === 'string'")
					.available-table__text(v-html="item.phone" )


</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
		};
	},

	methods: {
		dataSet(data) {
			this.data = data;
			this.acviveTabId = this.data?.tabs.find(tab => tab.active === true).tabId || null
		}
	}
};
</script>
