export default {
	namespaced: true,

	state: {
		bxajaxid: null,
		remote: null,
		linkProfile: null,
		id: null,
		name: null,
		loading: false
	},

	mutations: {
		set(state, data) {
			state.bxajaxid = data.bxajaxid;
			state.remote = data.remote;
			state.linkProfile = data.linkProfile;
			state.id = data.id;
			state.name = data.name;
		},

		setLoading(state, value) {
			state.loading = value;
		}
	},

	actions: {
		async request(context, options) {
			context.commit('setLoading', true);

			const response = await fetch(context.state.remote, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					...options,
					bxajaxid: context.state.bxajaxid
				})
			});

			if (!response.ok) {
				console.warn('[vuex] user: AJAX request failed', response);
			} else {
				const data = await response.json();
				console.log('[vuex] user: loaded', data);
				context.commit('set', data);
			}

			context.commit('setLoading', false);
		},

		async add(context, options) {
			context.dispatch('request', {
				action: 'add',
				id: options.id,
				count: options.count
			});
		},

		async remove(context, options) {
			context.dispatch('request', {
				action: 'remove',
				id: options.id
			});
		}
	}
};