<template lang="pug">
.v-offers.offers(v-if="data && data.items && data.items.length")
	.container
		.offers__container.container-inner
			.offers__heading
				.offers__heading-main
					h2.h2.offers__main-title {{ data.mainTitle }}
					.offers__content(:class="{ 'offers__content--top-sm': offersLight }")
						.offers__tabs-wrapper(v-if="tabs && tabs.length")
							template(v-for="(tab, index) in tabs" :key="tab.id || index")
								button.btn.tab.offers__tab(
									:class="{ 'active': activeIndex === index }"
									@click="toggleIndex(index)"
								)
									.tab__text {{ tab.tabName }}
										span(v-if="!offersLight")  ({{ tab.amtSlides }})
				.offers__heading-aside(v-if="data.aside && data.aside.items")
					v-base-slider(
						:options="asideOptions"
						:pagination="asideOptions.pagination"
						)
						v-swiper-slide(v-for="item in data.aside.items")
							.offers__heading-aside-slide
								.offers__heading-aside-img
									img.offers__heading-aside-image(v-if="item.image" :src="item.image")
								.offers__heading-aside-text {{ item.text }}
									.swiper-pagination.offers__swiper-pagination
			.offers__view-block(v-if="activeView" :class="{ 'offers__view-block--top-sm': offersLight }")
				.offers__image-wrapper(v-if="activeView.image && activeView.image.src")
					//template(v-if="activeView.background")
					//	img.absolute--full.image--full.offers__image-bg.offers__image-bg--down(v-if="activeView.background.down" :src="activeView.background.down")
					//	img.absolute--full.offers__image-bg.offers__image-bg--up(v-if="activeView.background.up" :src="activeView.background.up")
					img.absolute--full.image--full.offers__image(:src="activeView.image.src" :alt="activeView.image.alt")
					.offers__chapter
						.offers__chapter-title {{ activeView.title }}
						.offers__chapter-text {{ activeView.text }}
						a.link.btn.offers__chapter-link(v-if="activeView.link && activeView.btnText" :href="activeView.link") {{ activeView.btnText }}

				.offers__slider-wrapper(v-if="activeView.slides && activeView.slides.length" :class="{ 'slider--full-screen': offersLight }")
					template(v-if="offersLight")
						v-base-slider(
							:options="optionsLight"
							:id="uniqueId"
						)
							v-swiper-slide(v-for="(slide, index) in activeView.slides" :key="slide.id || index")
								v-product(:product="slide" template="grid")
					template(v-else)
						v-hero-slider(:data-source="{ 'slides': activeView.slides }" :options="offersLight ? optionsLight : options" pagination)
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import uniqueId from '../scripts/helpers/uniqueId.js'

export default {
	components: {
		'v-swiper-slide': SwiperSlide,
		'v-swiper': Swiper
	},

	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			activeIndex: 0,
			uniqueId: uniqueId('swiper-'),
			asideOptions: {
				slidesPerView: 1,
				spaceBetween: 0,
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					dynamicBullets: false
				}
			},
			options: {
				slidesPerView: 'auto',
				spaceBetween: 16,
				grabCursor: true,
				loop: true,
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 13
					}
				}
			},
			optionsLight: {
				slidesPerView: 'auto',
				spaceBetween: 16,
				grabCursor: true,
				arrows: true,
				loop: false,
				pagination: {
					el: '.offers__swiper-pagination',
					type: 'bullets'
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
					1280: {
						slidesPerView: 4,
						spaceBetween: 13
					}
				}
			},
			tabs: null,
			swiper: null
		};
	},

	computed: {
		activeView() {
			if (this.data.items[this.activeIndex])
				return this.data.items[this.activeIndex]
		},

		offersLight() {
			if (this.data.aside) {
				return !!this.data.aside
			}
		},
	},

	methods: {
		dataSet(data) {
			this.data = data

			if (this.data?.items?.length) {
				this.tabs = this.data.items.map(el => {
					return {
						id: el.id,
						tabName: el.tabName,
						amtSlides: el.slides.length
					}
				})
			}
		},

		toggleIndex(index) {
			if (this.$refs.activeView) {
				this.$refs.activeView.style.opacity = 0.75
				setTimeout(() => this.$refs.activeView.style.opacity = 1, 300)
			}
			this.activeIndex = index
		}
	}
};
</script>