<template lang="pug">
v-base-slider(v-if="slider" :options="computedOptions" :pagination="pagination")
	v-swiper-slide(v-for="(item, index) in slider.items" :key="index")
		.offers__heading-aside-slide
			.offers__heading-aside-img
				img.offers__heading-aside-image(v-if="item.image" :src="item.image")
			.offers__heading-aside-text {{item.text}}
				.swiper-pagination.offers__swiper-pagination
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';
import {SwiperSlide} from 'swiper/vue';

export default {
	components: {
		'v-swiper-slide': SwiperSlide
	},

	mixins: [
		sourceMixin
	],

	props: {
		options: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			slider: null,
			uniqueId: uniqueId('swiper-'),
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				dynamicBullets: false
			}
		};
	},

	computed: {
		computedOptions() {
			let result = {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				arrows: false
			};
			if (this.options) {
				Object.keys(this.options).forEach((key) => {
					if (typeof this.options[key] !== 'undefined') {
						result[key] = this.options[key];
					}
				});
			}
			return result;
		}
	},

	methods: {
		dataSet(data) {
			this.slider = data;
		}
	}
};
</script>