<template lang="pug">
svg.v-svg-icon.svg-icon
	use(:href="computedIcon")
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: ''
		},

		sprite: {
			type: String,
			default: '/local/assets/dist/icons/sprite.svg'
		}
	},

	computed: {
		computedIcon() {
			return this.sprite + '#svg-icon-' + this.icon;
		}
	}
};
</script>
