<template lang="pug">
label.v-field-checkbox.field-checkbox(:class="{'field-checkbox--disabled': disabled}")
	input.field-checkbox__input(
			type="checkbox"
			:value="trueValue"
			v-model="localValue"
			@change="handleInput"
			:disabled="disabled"
			:id="uniqueId")
	span.field-checkbox__visual
		v-svg-icon.field-checkbox__visual-icon(icon="check")
	span.field-checkbox__text(v-html="text")
</template>

<script>

import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		text: {
			type: [String, Number],
			default: null
		},

		modelValue: {
			type: [Object, String, Number, Boolean],
			default: null
		},

		trueValue: {
			type: [Object, String, Number],
			default: null
		},

		disabled: {
			type: Boolean,
			default: false
		},
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			uniqueId: uniqueId('field-')
		};
	},

	watch: {
		modelValue() {
			this.updateLocalValue();
		}
	},

	created() {
		this.updateLocalValue();
	},

	methods: {
		updateLocalValue() {
			this.localValue = this.modelValue;
		},

		handleInput() {
			this.$emit('update:model-value', this.localValue, this.field);
		}
	}
};
</script>
