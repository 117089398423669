<template lang="pug">
.v-contacts.contacts(v-if="contacts")
	.contacts__container.container
		.contacts__wrap
			h1.contacts__title.h2(v-if="contacts.title") {{contacts.title}}
			.contacts__items.fls
				template(v-for="(item, index) in contacts.items" :key="index")
					.contacts__item
						.contacts__item-left
							.contacts__item-name(v-if="item.name") {{item.name}}
							.contacts__item-text(v-if="item.text" v-html="item.text")
						.contacts__item-right(v-if="(item.phones && item.phones.length) || (item.emails && item.emails.length)")
							.contacts__item-contacts-wrap(v-if="item.phones && item.phones.length")
								v-svg-icon.contacts__item-icon(icon="contact-phone")
								.contacts__item-contacts
									template(v-for="(phone, phoneIndex) in item.phones" :key="phoneIndex")
										a.contacts__item-contact(:href="phone.link") {{phone.text}}
							.contacts__item-contacts-wrap(v-if="item.emails && item.emails.length")
								v-svg-icon.contacts__item-icon(icon="contact-email")
								.contacts__item-contacts
									template(v-for="(email,emailIndex) in item.emails" :key="emailIndex")
										a.contacts__item-contact(:href="email.link") {{email.text}}
			.contacts__addresses
				.contacts__addresses-left
					h2.contacts__addresses-heading.h4 {{contacts.addressesTitle}}
					.contacts__addresses-title.h6 {{activeAddress.name}}:
					.contacts__addresses-name {{activeAddress.address}}
				.contacts__addresses-right
					.contacts__blue-tabs
						.blue-tabs.scrollbar-none
							template(v-for="(address, addressIndex) in contacts.addresses" :key="addressIndex")
								.blue-tabs__item-wrap
									button.blue-tabs__item.btn(type="button"
											@click="showAddress(address)"
											:class="{'blue-tabs__item--active': address.name === activeAddress.name}")
										| {{address.name}}
					.contacts__map(v-if="activeAddress")
						v-map.ymap__map.flc(:markers="[activeAddress]"
								:coords="activeAddress.coords"
								:zoom="contacts.mapZoom"
								ref="map")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			contacts: null,
			activeAddress: null
		};
	},

	methods: {
		showAddress(address) {
			this.activeAddress = address;
		},

		dataSet(data) {
			this.contacts = data;
			this.activeAddress = this.contacts.addresses[0];
		}
	}
};

</script>