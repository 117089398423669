<template lang="pug">
.v-questions.questions(v-if="data && data.items && data.items.length" :class="{'questions--full': data.categories}")
	v-loader(:loading="loading")
	.container
		.questions__container
			.questions__header
				h2.questions__title.h2 {{data.title}}
				.questions__note(v-if="data.note")
					img.questions__note-icon(:src="noteIcon" alt="" loading="lazy")
					.questions__note-text {{data.note}}

			.questions__tabs(v-if="data.categories")
				.big-tabs
					template(v-for="(category, categoryIndex) in data.categories" :key="categoryIndex")
						button.big-tabs__item(type="button"
								:class="{'active': category.id === activeCategory.id}"
								@click="setActiveCategory(category); filterItems()")
							.big-tabs__item-text {{category.name}}
							//- |&nbsp;
							//-.big-tabs__item-count ({{category.count}})

			.questions__content
				template(v-if="data.categories")
					template(v-for="(item, index) in filteredItems" :key="index")
						.questions__col
							.questions__item(@click="openItem(item.id)")
								.questions__item-heading
									.questions__item-question {{ item.question }}
									.questions__btn(:class="{ 'close': !item.isOpen }")
										v-svg-icon.questions__icon.questions__icon--plus(icon="plus")
										v-svg-icon.questions__icon.questions__icon--minus(icon="minus")
								v-collapse.questions__collapse(v-if="item.answer" :open="item.isOpen")
									.questions__collapse
										.questions__item-answer
											.questions__item-answer-content.text-guide(v-html="item.answer")
											.questions__item-table.text-guide(v-if="item.table" v-html="item.table")
				template(v-else)
					template(v-for="(item, index) in data.items" :key="index")
						template(v-if="index < 8")
							.questions__col
								.questions__item(@click="openItem(item.id)")
									.questions__item-heading
										.questions__item-question {{ item.question }}
										.questions__btn(:class="{ 'close': !item.isOpen }")
											v-svg-icon.questions__icon.questions__icon--plus(icon="plus")
											v-svg-icon.questions__icon.questions__icon--minus(icon="minus")
									v-collapse.questions__collapse(v-if="item.answer" :open="item.isOpen")
										.questions__collapse
											.questions__item-answer
												.questions__item-answer-content.text-guide(v-html="item.answer")
												.questions__item-table.text-guide(v-if="item.table" v-html="item.table")
						template(v-if="index >= 8 && isAllShown")
							.questions__col
								.questions__item(@click="openItem(item.id)")
									.questions__item-heading
										.questions__item-question {{ item.question }}
										.questions__btn(:class="{ 'close': !item.isOpen }")
											v-svg-icon.questions__icon.questions__icon--plus(icon="plus")
											v-svg-icon.questions__icon.questions__icon--minus(icon="minus")
									v-collapse.questions__collapse(v-if="item.answer" :open="item.isOpen")
										.questions__collapse
											.questions__item-answer
												.questions__item-answer-content.text-guide(v-html="item.answer")
												.questions__item-table.text-guide(v-if="item.table" v-html="item.table")
			.questions__action(v-if="(!data.categories || (data.categories && data.categories.length === 0)) && data.items.length > 8 && !isAllShown")
				button.btn.questions__button-more(@click="showAll")
					.questions__button-round
						v-svg-icon.questions__button-icon(icon="arrow-down")
					span Все вопросы
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue'

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			filteredItems: null,
			loading: false,
			noteIcon: '/local/images/bubble-star-azure.svg',
			activeCategory: null,
			isAllShown: false
		}
	},

	methods: {
		filterItems() {
			this.filteredItems = this.data.items.filter(item => item.categories ? item.categories.findIndex(category => category === this.activeCategory.id) > -1 : false);
		},

		setActiveCategory(category) {
			this.activeCategory = category;
		},

		openItem(id) {
			this.data.items.forEach(item => {
				if (item.id === id) {
					item.isOpen = !item.isOpen
				}
			})
		},

		showAll() {
			this.isAllShown = true
		},

		dataSet(data) {
			this.data = data;
			if (this.data.categories) {
				this.setActiveCategory(this.data.categories.find(item => item.active));
				if (this.data.categories) {
					this.filterItems();
				}
			}
		},
	}
}
</script>
