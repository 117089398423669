import {slideUp} from "../../../helpers/slideUp";
import {slideDown} from "../../../helpers/slideDown";
import breakpoints from "scripts/breakpoints.js";

export const bottomMenuAcc = (scope) => {

    if (!window.matchMedia("(max-width: 1023px)").matches) {
        return
    }

    let buttons = scope.querySelectorAll('.js-bottom-menu-acc-btn');

    buttons.forEach(btn => {
        btn.removeEventListener('click', toggleBottomMenuAcc);
        btn.addEventListener('click', toggleBottomMenuAcc);
    })
}

function toggleBottomMenuAcc() {
    let main = this.closest('.js-bottom-menu-acc');
    let body = main?.querySelector('.js-bottom-menu-body');

    if (main && body) {
        if (main.classList.contains('open')) {
            main.classList.remove("open");
            slideUp(body, 500);
        } else {
            main.classList.add("open");
            slideDown(body, 500);
        }
    }
}