<script>
export default {
	props: {
		dataSource: {
			type: [Object, String],
			default: null
		},

		bxajaxid: {
			type: String,
			default: null
		}
	},

	render() {
		return null;
	}
};
</script>