<template lang="pug">
.v-tabs.tabs(v-if="tabsData")
	.tabs__container.container
		.tabs__header
			.tabs__header-left
				h2.tabs__title(v-if='tabsData.title') {{tabsData.title}}
				.product-slider__banner.hide__pc(v-if="tabsData.bannerMob" v-html="tabsData.bannerMob")
				.tabs__tabs.scrollbar-none(:class='tabsData.addClass')
					template(v-for="(tab, tabIndex) in tabsData.tabs" :key="tabIndex")
						template(v-if="tabsData.tabs.length > 10 && !isSm ? (isSpoilerOpened ? true : tabIndex < 10) : true")
							template(v-if="tab.link")
								.tabs__item
									a.btn.tab.offers__tab(:class="{'active': currentTabIndex === tabIndex}"
											:href="tab.link"
											:target="getLinkTarget(tab.link)")
										.tab__text {{tab.name}}
							template(v-else)
								.tabs__item
									button.btn.tab.offers__tab(:class="{'active': currentTabIndex === tabIndex}"
											type="button"
											@click="currentTabIndex = tabIndex")
										.tab__text {{tab.name}}
					.tabs__item(v-if="tabsData.tabs.length > 10 && !isSpoilerOpened && !isSm")
						button.btn.tab.offers__tab(@click="showMore")
							.tab__text Ещё
			.tabs__header-right(v-if="$slots['small-slider']")
				.offers__heading-aside
					slot(name="small-slider")
	.tabs__content-wrap
		template(v-if="tabsData.type === 'slots'")
			template(v-for="(tab, tabIndex) in tabsData.tabs" :key="tabIndex")
				.tabs__content(v-if="currentTabIndex === tabIndex" :class="tab.contentClassName")
					slot(:name="tab.slotName")
		template(v-if="tabsData.type === 'html'")
			template(v-for="(tab, tabIndex) in tabsData.tabs" :key="tabIndex")
				.tabs__content.text-guide(v-if="currentTabIndex === tabIndex" v-html="tab.content" :class="tab.contentClassName")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue';
import linkTargetMixin from '../components/mixins/v-mixin-link-target.vue';

export default {
	mixins: [
		sourceMixin,
		isMobMixin,
		linkTargetMixin
	],

	data() {
		return {
			currentTabIndex: 0,
			tabsData: null,
			isSpoilerOpened: false
		};
	},

	methods: {
		showMore() {
			this.isSpoilerOpened = true;
		},

		dataSet(data) {
			this.tabsData = data;
			const currentTabIndex = this.tabsData.tabs.findIndex(item => item.active);
			if (currentTabIndex > -1) {
				this.currentTabIndex = currentTabIndex;
			}
		}
	}
};
</script>
