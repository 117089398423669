<template lang="pug">
.v-field-tags.field-tags(v-if="field")
	.catalog__tags
		template(v-for="(option, index) in field.options" :key="index")
			.catalog__tag(:class="{'active': option.value === localValue}" @click="localValue = option.value; handleInput();") {{option.text}}
</template>

<script>
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: [Object, String, Number, Boolean, Symbol, Date],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			open: false,
			uniqueId: uniqueId('field-select_')
		};
	},

	computed: {
		activeOption() {
			if (this.field && this.field.options && this.field.options.length) {
				return this.field.options.find(option => option.value === this.localValue);
			}
			return null;
		}
	},

	watch: {
		modelValue() {
			this.updateLocalValue();
		}
	},

	created() {
		this.updateLocalValue();
		document.addEventListener('click', this.handleClickOutside);
		document.addEventListener('keydown', this.handleEsc);
	},

	unmounted() {
		document.removeEventListener('click', this.handleClickOutside);
		document.removeEventListener('keydown', this.handleEsc);
	},

	methods: {
		updateLocalValue() {
			this.localValue = this.modelValue;
		},

		handleInput() {
			this.$emit('update:model-value', this.localValue);
		},

		handleClickOutside(e) {
			if (!this.$el.contains(e.target)) {
				this.open = false;
			}
		},

		handleEsc(e) {
			if (e.keyCode == 27 && this.open) {
				e.stopPropagation();
				this.open = false;
			}
		}
	}
};
</script>