<template lang="pug">
.v-breadcrumbs.breadcrumbs(v-if="items && items.length")
	.breadcrumbs__container.container
		.breadcrumbs__list
			template(v-for="(item, i) in items" :key="i")
				template(v-if="i === items.length - 1")
					.breadcrumbs__item
						.breadcrumbs__item-text {{item.title}}
				template(v-else-if='item.link')
					a.breadcrumbs__item(:href="item.link")
						.breadcrumbs__item-text {{item.title}}
						v-svg-icon.breadcrumbs__chevron(icon="breadcrumbs-chevron")
				template(v-else)
					span.breadcrumbs__item
						.breadcrumbs__item-text {{item.title}}
						v-svg-icon.breadcrumbs__chevron(icon="breadcrumbs-chevron")
</template>

<script>
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue';

export default {
	mixins: [
		isMobMixin
	],

	computed: {
		items() {
			return this.$store.getters['breadcrumbs/items'];
		},
	},
};
</script>
