<template lang="pug">
.v-base-slider.base-slider
	v-loader(:loading="loading")
	button.hero-slider__arrow.hero-slider__arrow--prev(type="button" v-if="(slidesLimit && itemsLength) ? slidesLimit < itemsLength : true" ref="prev" :class="`${uniqueId}`")
		v-svg-icon.hero-slider__icon(icon="slider-left")
	button.hero-slider__arrow.hero-slider__arrow--next(type="button" v-if="(slidesLimit && itemsLength) ? slidesLimit < itemsLength : true" ref="next" :class="`${uniqueId}`")
		v-svg-icon.hero-slider__icon(icon="slider-right")
	v-swiper-slider(
		:options="computedOptions"
		:navigation="computedNavigation"
		:key="uniqueId"
		:pagination="pagination"
		:ref="`swiperSlider-${uniqueId}`"
	)
		slot
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';


export default {

	mixins: [
		sourceMixin
	],

	props: {
		options: {
			type: Object,
			default: null
		},
		pagination: {
			type: Boolean,
			default: false
		},
		slidesLimit: {
			type: Number,
			default: 0
		},
		itemsLength: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			slider: {},
			uniqueId: uniqueId('swiper-')
		};
	},

	computed: {
		computedOptions() {
			let result = {
				slidesPerView: 1,
				spaceBetween: 16
			};
			if (this.options) {
				Object.keys(this.options).forEach((key) => {
					if (typeof this.options[key] !== 'undefined') {
						result[key] = this.options[key];
					}
				});
			}
			return result;
		},

		computedNavigation() {
			return {
				prevEl: `.hero-slider__arrow--prev.${this.uniqueId}`,
				nextEl: `.hero-slider__arrow--next.${this.uniqueId}`
			};
		}
	},

	methods: {
		dataSet(data) {
			this.slider = data;
		}
	}
};
</script>