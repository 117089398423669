<template lang="pug">
.v-ymap.ymap(v-if="data")
	.ymap__container.container
		h2.ymap__title.h2(v-if="data.title") {{data.title}}
		.ymap__wrap
			v-map.ymap__map.flc(:markers="data.items" :coords="data.mapCenterCoords" :zoom="data.mapZoom" @click-marker="clickMarkerHandler" clusters ref="map")
			//.ymap__filters(v-if="data.filters")
			//	.ymap__filter(v-for="(filter, filterInd) in data.filters" :key="filterInd")
			//		v-field.ymap__filter-field(:field="filter"
			//			appearance="form"
			//			v-model="filter.value"
			//			@update:model-value="filterChanged")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			// timerId: null
		};
	},

	methods: {
		clickMarkerHandler() {

		},

		// getFiltersValue() {
		// 	let result = {};
		// 	if (this.data && this.data.filters && this.data.filters.length > 0) {
		// 		this.data.filters.forEach(filter => {
		// 			result[filter.name] = {
		// 				value: filter.value,
		// 				type: filter.type
		// 			};
		// 		});
		// 	}
		// 	return result;
		// },
		//
		//
		// filterChanged() {
		// 	clearTimeout(this.timerId);
		// 	this.timerId = setTimeout(() => {
		// 		this.dataLoad({
		// 			url: this.data.remote,
		// 			delay: true,
		// 			data: {
		// 				filters: this.getFiltersValue()
		// 			}
		// 		});
		// 	}, 1500)
		// },

		dataSet(data) {
			this.data = data;
		}
	}
};
</script>
