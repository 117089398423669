<template lang="pug">
.v-catalog-banner.catalog__banner(v-if="banner && banner.image" :style="{backgroundImage: `url(${isSm ? (isXs ? banner.image?.srcXs : banner.image?.srcSm) : banner.image?.src})`}")
	.catalog__banner-title(v-if="banner.title") {{banner.title}}
	a.catalog__banner-action.btn.btn--yellow(v-if="banner.action" :href="banner.action.link") {{banner.action.text}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import mixinIsMob from '../components/mixins/v-mixin-is-mob.vue';

export default {
	mixins: [
		sourceMixin,
		mixinIsMob
	],

	data() {
		return {
			banner: null
		};
	},

	methods: {
		dataSet(data) {
			this.banner = data;
		}
	}
};
</script>
