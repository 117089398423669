<template lang="pug">
.v-rating.rating(:class="{'rating--input': input}")
	.rating__layout
		.rating__row
			.rating__layer.rating__layer--unchecked(v-if='!input')
				.rating__grid
					template(v-for="val in 5")
						label.rating__item
							v-svg-icon.rating__icon(icon="rating-star")
			template(v-if='!input')
				.rating__layer.rating__layer--checked(:style="{'width': field.value * 20 + '%'}")
					.rating__grid
						template(v-for="val in 5")
							label.rating__item(:class="{'rating__item--checked': val <= localValue}")
								v-svg-icon.rating__icon(icon="rating-star")
			template(v-if='input')
				.rating__layer.rating__layer--input
					label.field-label(v-if="field") {{field.label}}
					.rating__grid
						template(v-for="val in 5")
							label.rating__item(:class="{'rating__item--checked': val <= localValue, 'rating__item--disabled': field.disabled}")
								input.rating__input(type="radio"
										:value="val + 1"
										v-model="tempValue"
										@change="handleInput(val)"
										:disabled="field.disabled"
										:aria-labelledby="(labelId ? labelId + ' ' + uniqueId : null)"
										:id="uniqueId")
								v-svg-icon.rating__icon(icon="rating-star")
</template>

<script>
import formattingMixin from '../components/mixins/v-mixin-formatting.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	mixins: [
		formattingMixin
	],

	props: {
		input: {
			type: Boolean,
			default: false
		},

		modelValue: {
			type: [Object, String, Number, Boolean],
			default: null
		},

		field: {
			type: Object,
			default: null
		},

		labelId: {
			type: String,
			default: null
		},
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localName: 'rating-' + this.uniqueId,
			uniqueId: uniqueId('field-'),
			localValue: null,
			tempValue: null
		};
	},

	created() {
		this.updateLocalValue();
	},

	methods: {
		updateLocalValue() {
			if (this.field) {
				this.localValue = this.field.value;
			}
		},

		handleInput(val) {
			this.localValue = val;
			this.$emit('update:model-value', this.localValue);
		}
	}
};
</script>