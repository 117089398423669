<template lang="pug">
.v-map.map
	.map__map(ref="map")
</template>

<script>
import mitt from 'mitt';
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue';

let map = null;
let mapMarkers = [];

export default {
	mixins: [
		isMobMixin
	],

	props: {
		coords: {
			type: Array,
			validator(val) {
				return val && val.length === 2 && typeof val[0] == 'number' && typeof val[1] == 'number' && !isNaN(val[0]) && !isNaN(val[1]);
			},
			default() {
				return [53.902284, 27.561831]; // Minsk coordinates
			}
		},

		zoom: {
			type: Number,
			validator(val) {
				return !isNaN(val);
			},
			default: 12
		},

		adjustInitialBounds: {
			type: Boolean,
			default: true
		},

		rightControls: {
			type: Boolean,
			default: false
		},

		clusters: {
			type: Boolean,
			default: false
		},

		markers: {
			type: Array,
			default: null
		},

		activeMarkerId: {
			type: String,
			default: null
		},
	},

	data() {
		return {
			currentLocation: null,
			localActiveMarkerId: null,
			events: mitt()
		};
	},

	watch: {
		markers() {
			if (map) {
				this.setMarkers();
				if (this.coords) {
					map.setCenter(this.coords);
				}
			}
		},

		activeMarkerId() {
			if (this.markers && this.markers.length && this.activeMarkerId) {
				this.localActiveMarkerId = this.activeMarkerId;
				let activeMarkerIndex = this.markers.findIndex(marker => marker.id === this.localActiveMarkerId)
				if (activeMarkerIndex > -1) {
					this.activateMarker(activeMarkerIndex);
					if (this.markers.find(marker => marker.id === this.localActiveMarkerId)) {
						this.shownOnMap(this.markers.find(marker => marker.id === this.localActiveMarkerId).coords);
						mapMarkers[activeMarkerIndex].balloon.open();
					}
				}
			}
		}
	},

	async mounted() {
		this.events.on('adjust-bounds', this.adjustBounds);
		this.events.on('open-balloon', (index) => {
			if (mapMarkers) {
				mapMarkers[index].balloon.open();
			}
		});
		this.events.on('highlight-balloon', (index) => {
			if (mapMarkers && mapMarkers[index]) {
				mapMarkers[index].options.set('iconImageHref', '/local/assets/images/map-marker-highlight.svg');
			}
		});
		this.events.on('unhighlight-balloon', (index) => {
			if (mapMarkers && mapMarkers[index]) {
				mapMarkers[index].options.set('iconImageHref', '/local/assets/images/map-marker-default.svg');
			}
		});
		this.events.on('set-route-to', this.makeRoute);
		this.events.on('refresh', this.setMarkers);
		await this.$store.dispatch('location/loadAPI');
		this.initMap();
	},

	methods: {
		activateMarker(index) {
			if (index > -1) {
				let $allPins = this.$el.querySelectorAll('.map__pin');
				$allPins.forEach($elem => {
					$elem.classList.remove('active');
				});
				this.$el.querySelector(`.map__pin[data-index="${index}"]`).classList.add('active');
			}
		},

		shownOnMap(coords) {
			map.setCenter(coords);
			map.setZoom(17);
		},

		//showCurrentBallon(index) {
		//	mapMarkers[index].balloon.open();
		//},

		// Прокладывает маршрут
		makeRoute(point) {
			// eslint-disable-next-line no-undef
			ymaps.geolocation.get({
				provider: 'auto', // опция для определения положения по ip
				autoReverseGeocode: true // автоматическое геокодируем полученного результата
			}).then((result) => {
				this.currentLocation = /*this.$store.getters['location/coords'] ||*/ result.geoObjects.get(0).geometry.getCoordinates();
				if (point) {
					// eslint-disable-next-line no-undef
					let multiRoute = new ymaps.multiRouter.MultiRoute({
						// точки маршрута (могут быть заданы как координатами, так и адресом)
						referencePoints: [
							this.currentLocation,
							point
						]
					}, {
						boundsAutoApply: true
					});
					if (multiRoute) {
						map.geoObjects.removeAll();
						this.setMarkers();
						map.geoObjects.add(multiRoute);
					}
				} else {
					return false;
				}
			});
		},

		adjustBounds() {
			if (map) {
				this.setMarkers();
				try {
					this.setBounds(map.geoObjects.getBounds(), {checkZoomRange: true}).then(() => {
						if (map.getZoom() > 16) {
							map.setZoom(16);
						}
					});
				} catch (e) {}
			}
		},

		getBaloonContent(marker) {
			let markerNameTemplate = '';
			let markerAddressTemplate = '';
			let markerPhoneTemplate = '';
			let markerScheduleTemplate = '';
			let markerPriceTemplate = '';
			if (marker.name) {
				markerNameTemplate += `<div class="map__balloon-name flc">${marker.name}</div>`;
			}
			if (marker.address) {
				markerAddressTemplate += `<div class="map__balloon-address flc">${marker.address}</div>`;
			}
			if (marker.phone && typeof marker.phone === 'string') {
				markerPhoneTemplate += `<div class="map__balloon-phone flc">
					<svg class="v-svg-icon svg-icon map__balloon-phone-icon">
						<use href="/local/assets/dist/icons/sprite.svg#svg-icon-phone"></use>
					</svg>
					<div class="map__balloon-phone-text">${marker.phone}</div>
				</div>`;
			} else if (marker.phone && Array.isArray(marker.phone) && marker.phone.length) {
				markerPhoneTemplate += `<div class="map__balloon-phone flc">
					<svg class="v-svg-icon svg-icon map__balloon-phone-icon">
						<use href="/local/assets/dist/icons/sprite.svg#svg-icon-phone"></use>
					</svg>
					<div class="map__balloon-phone-wrap">`;
				marker.phone.forEach(item => {
					markerPhoneTemplate += `<a class="map__balloon-phone-text" href="${item.link}">${item.text}</a>`
				});
				markerPhoneTemplate += `</div></div>`
			}
			if (marker.schedule) {
				markerScheduleTemplate += `<div class="map__balloon-schedule flc">
					<svg class="v-svg-icon svg-icon map__balloon-phone-icon">
						<use href="/local/assets/dist/icons/sprite.svg#svg-icon-clock-2"></use>
					</svg>
					<div class="map__balloon-schedule-text">${marker.schedule}</div>
				</div>`;
			}
			if (marker.price) {
				markerPriceTemplate += `<div class="map__balloon-price flc">цена: ${marker.price} р.</div>`;
			}
			return `
				<div class="map__balloon">
					${markerNameTemplate}
					${markerAddressTemplate}
					${markerPhoneTemplate}
					${markerScheduleTemplate}
					${markerPriceTemplate}
				</div>
			`;
		},

		setMarkers() {
			// clear map
			map.geoObjects.removeAll();
			mapMarkers.splice(0, mapMarkers.length);

			// create ymaps markers
			if (this.markers) {
				this.markers.forEach((marker, index) => {
					let markerType = capitalize(marker.markerType) || 'Placemark';
					let properties = {
						hintContent: marker.name || null,
						balloonContent: this.getBaloonContent(marker),
						iconCaption: marker.price ? marker.price + ' р.' : null,

						//balloonContentHeader: marker.type || null,
						//balloonContentBody: this.getBaloonContent(marker) || null,
						//balloonContentFooter: marker.balloonFooter || null,
						//iconContent: `
						//	<div class="map__pin ${(marker.id === this.localActiveMarkerId) ? 'active' : ''}" data-index="${index}">
						//		<svg class="v-svg-icon svg-icon map__pin-image">
						//			<use href="/local/assets/dist/icons/sprite.svg#svg-icon-map-marker"></use>
						//		</svg>
						//	</div>
						//`
						price: marker.price
					};
					//let iconImageWidth = 40;
					//let iconImageHeight = 54;

					//let MyBalloonLayout = ymaps.templateLayoutFactory.createClass(this.getBaloonContent(marker));

					//let getPrice = ymaps.templateLayoutFactory.createClass("{{properties.iconContent}}", {
					//	build: function() {
					//		console.log(2)
					//	}
					//});

					let options = {
						id: marker.id,
						//iconLayout: 'default#imageWithContent',
						//iconImageHref: '/local/assets/images/map-marker-default.svg',
						//iconImageHref: '',
						//iconImageSize: [iconImageWidth, iconImageHeight],
						//iconImageOffset: [-iconImageWidth / 2, -iconImageHeight],
						//hideIconOnBalloonOpen: false,
						//balloonShadow: false,
						//balloonLayout: MyBalloonLayout,
						//balloonContentLayout: MyBalloonContentLayout,
						//balloonPanelMaxMapArea: 0
						preset: 'islands#greenCircleDotIcon',
						hideIconOnBalloonOpen: true,
						//iconColor: '#56db40'
						//iconColor: '#614AEC'
						price: marker.price
					};
					// eslint-disable-next-line no-undef
					let ymarker = new ymaps[markerType](sanitizeCoords(marker.coords), properties, options);
					map.geoObjects.add(ymarker);
					//ymarker.events.add('click', () => {
					//	this.$emit('click-marker', marker);
					//	this.localActiveMarkerId = marker.id;
					//	this.shownOnMap(marker.coords);
					//	this.activateMarker(index);
					//	if (this.isXs) {
					//		this.$emit('open-mob-balloon', marker);
					//	}
					//});
					// use clusterization
					mapMarkers.push(ymarker);
					//map.events.add('click', e => e.get('target').balloon.close());
				});
				// eslint-disable-next-line no-undef

				let getPriceLayout = ymaps.templateLayoutFactory.createClass("{{properties.iconContent}}", {
					build: function() {
						getPriceLayout.superclass.build.call(this);
						let parentElement = this.getParentElement();
						let geoObjects = this.getData().properties._data.geoObjects;
						let pricaArray = [];
						geoObjects.forEach(geoObject => {
							pricaArray.push(geoObject.properties._data.price);
						});
						parentElement.innerHTML = `
							<div class="map__cluster">
								<span>от</span>&nbsp;<span class="map__cluster-price">${Math.min(...pricaArray)}</span><span>&nbsp;р</span>
							</div>
						`;
					}
				});

				let clusterer = new ymaps.Clusterer({
					preset: 'islands#invertedDarkGreenClusterIcons',
					groupByCoordinates: false,
					clusterIconContentLayout: getPriceLayout,
					clusterDisableClickZoom: false,
					clusterHideIconOnBalloonOpen: false,
					geoObjectHideIconOnBalloonOpen: false,
					// hasBalloon: false,
					// hasHint: false,
					zoomMargin: [100, 100, 100, 100],
					// clusterIcons: [
					// 	{
					// 		href: '',
					// 		size: [92, 92],
					// 		offset: [-92 / 2, -92 / 2]
					// 	}
					// ],
					// clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
					// 	`<div class="yandex-map__cluster">
					// 		{{ properties.geoObjects.length }}
					// 	</div>`),
				});
				clusterer.add(mapMarkers);
				map.geoObjects.add(clusterer);
				//map.setBounds(clusterer.getBounds());
				//if (map.getZoom() > 12) {
				//	map.setZoom(12);
				//}
			}
			function capitalize(string) {
				return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
			}
			function sanitizeCoords(arr) {
				return arr.map(function (item) {
					return Array.isArray(item) ? sanitizeCoords(item) : +item;
				});
			}
		},

		initMap() {
			// eslint-disable-next-line no-undef
			map = new ymaps.Map(this.$refs['map'], {
					center: this.coords,
					zoom: +this.zoom,
					controls: []
				},
				{
					balloonPanelMaxMapArea: 0	// always show balloons as balloons (no panel mode)
				});
			map.behaviors.disable('scrollZoom');
			if (this.rightControls) {
				map.controls.add('zoomControl', {
					position: {
						right: '16px',
						top: '16px'
					}
				});
			} else {
				map.controls.add('zoomControl', {
					position: {
						left: '16px',
						top: '16px'
					}
				});
			}
			//if (controller.touch) {
			//	map.behaviors.disable('drag');
			//}
			this.setMarkers();
			//if (this.adjustInitialBounds) {
			//	this.adjustBounds();
			//}
			//map.geoObjects.events.add('balloonopen', (e) => {
			//	console.log('b o', e);
			//});
			//map.geoObjects.events.add('balloonclose', (e) => {
			//	console.log('b c', e.get('target'));
			//});
		}
	}
};
</script>