<template lang="pug">
.v-articles-grid.articles-grid(v-if="catalog" :class="{'articles-grid--full': catalog.view === 'full'}")
	v-loader(:loading="loading")
	.articles-grid__grid.scrollbar-none(v-if="catalog.items")
		.articles-grid__col(v-for="(item, index) in catalog.items" :key="index")
			.articles-grid__item
				.article-card
					a.article-card__link(v-if="item.link" :href="item.link") {{item.title}}
					.article-card__banner(v-if="item.image")
						img.article-card__banner-img(:src="item.image.src" :alt="item.image.alt")
					.article-card__content
						.article-card__title(v-if="item.title") {{item.title}}
						.article-card__time(v-if="item.time")
							v-svg-icon.article-card__time-icon(icon="clock")
							span.article-card__time-text {{item.time}}
	template(v-if="catalog.more && catalog.more.link")
		.articles-grid__more
			a.btn.articles-grid__more-btn(v-if="!isSm" :href="catalog.more.link") {{catalog.more.text}}
			a.more-btn(v-if="isSm" :href="catalog.more.link")
				span.more-btn__round
					v-svg-icon.more-btn__icon(icon="arrow-right")
				span.more-btn__text {{catalog.more.text}}
	template(v-else-if="catalog.more && catalog.page && catalog.page.count > catalog.page.offset + catalog.page.limit")
		.articles-grid__more
			button.btn.articles-grid__more-btn(type="button" @click="showMore") {{catalog.more.text}}
</template>

<script>

import sourceMixin from '../components/mixins/v-mixin-source.vue';
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue';

export default {
	mixins: [
		sourceMixin,
		isMobMixin
	],

	data() {
		return {
			catalog: null,
			showAll: false,
			state: {
				page: null
			}
		}
	},

	methods: {
		requestDataLoad(dataSetOptions) {
			this.dataLoad({
				url: this.catalog.remote,
				delay: true,
				data: {
					page: this.state.page
				}
			}, dataSetOptions);
		},

		showMore() {
			this.state.page.offset = this.catalog.page.offset + this.catalog.page.limit;
			this.requestDataLoad({append: this.catalog.page.append});
		},

		dataSet(data, options = {}) {
			if (options.append && this.catalog && this.catalog.items) {
				let oldItems = this.catalog.items;
				this.catalog = data;
				this.catalog.items = oldItems.concat(this.catalog.items);
			} else {
				this.catalog = data;
			}
			this.state.page = this.catalog.page;
		}
	}
}

</script>
