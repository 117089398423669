/* Крошки */
export default {
	namespaced: true,

	state: {
		bxajaxid: null,
		items: []
	},

	getters: {
		items(state) {
			return state.items;
		},
	},
	mutations: {
		set(state, data) {
			state.bxajaxid = data.bxajaxid;
			state.items = data.items;
		},

	},
	actions: {

	}
};
